import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
const USERS = "users";
const CREATE_USER = "create_user";
const UPDATE_USER = "update_user";
const DELETE_USER = "delete_user";

export const loadUsers = createAsyncThunk(
  `${ADMIN}/${USERS}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/users`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const showUser = createAsyncThunk(`${ADMIN}/${"SHOW_USERS"}`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/users/${id}`;
  const method = "GET";

  return handleAPI(url, {}, method);
});

export const createUser = createAsyncThunk(
  `${ADMIN}/${CREATE_USER}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/users`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateUser = createAsyncThunk(
  `${ADMIN}/${UPDATE_USER}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/users/${data.user.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteUser = createAsyncThunk(`${ADMIN}/${DELETE_USER}`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/users/${id}`;
  const method = "DELETE";

  return handleAPI(url, {}, method);
});
