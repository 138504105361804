import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootSlices from "./rootSlices";
const listenerMiddleware = createListenerMiddleware();

const store = configureStore({
  reducer: rootSlices,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      listenerMiddleware.middleware,
      thunk
    ),
});

export default store;
