import { createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESS_TOKEN, USER, LOGIN_TYPE, LOCALE } from "models";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const AUTH = "auth";

const LOGIN = "login";
const REST_PASSWORD = "reset_password";
const CHANGE_PASSWORD = "change_password";
const LOGOUT = "logout";

export const getAuthToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setAuthToken = (accessToken) => {
  return localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const setAuthUser = (user) => {
  return localStorage.setItem(USER, JSON.stringify({ ...user }));
};

export const getAuthUser = () => {
  const user = localStorage.getItem(USER);
  return JSON.parse(user);
};

export const removeAuthToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN);
};

export const removeAuthUser = () => {
  localStorage.removeItem(USER);
  return removeAuthToken();
};

export function setLocale(locale = "en") {
  return localStorage.setItem(LOCALE, locale);
}

export const login = createAsyncThunk(`${AUTH}/${LOGIN}`, (data) => {
  const url = `/${API_VERSION_ONE}/auth/sessions`;
  const method = "POST";
  const login_type = LOGIN_TYPE;

  return handleAPI(url, {}, method, { ...data, login_type });
});

export const resetPassword = createAsyncThunk(
  `${AUTH}/${REST_PASSWORD}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/auth/passwords`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const changePassword = createAsyncThunk(
  `${AUTH}/${CHANGE_PASSWORD}`,
  ({ token, user_id, ...data }) => {
    const method = "PUT";
    if (token) {
      const url = `/${API_VERSION_ONE}/auth/passwords`;
      return handleAPI(url, { token }, method, data);
    } else {
      const url = `/${API_VERSION_ONE}/admin/users/${user_id}/change_password`;
      return handleAPI(url, {}, method, data);
    }
  }
);

export const logout = createAsyncThunk(`${AUTH}/${LOGOUT}`, () => {
  const url = `/${API_VERSION_ONE}/auth/sessions`;
  const method = "DELETE";
  const data = {
    access_token: getAuthToken(),
  };

  return handleAPI(url, {}, method, data);
});
