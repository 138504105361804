import React from "react";
import PropTypes from "prop-types";
import { OutlinedInput, TextField } from "@mui/material";
import clsx from "clsx";
import { isAr } from "utils/uiHelpers";
import { MuiBox } from "..";
import { ReactComponent as ShowIcon } from "./icons/show-password.svg";
import { ReactComponent as HideIcon } from "./icons/hide-password.svg";
import styles from "./styles.module.css";

const fieldIconPos = {
  style: {
    left: isAr() ? "5%" : "unset",
    right: isAr() ? "unset" : "5%",
  },
};

const MuiTextInputHandler = ({ type, noBorder, isMedium, ...props }) => {
  const [textType, setTextType] = React.useState(type);

  const onTypeChange = () => {
    const newType = textType === "password" ? "text" : "password";
    setTextType(newType);
  };

  const getTextFieldByType = () => {
    switch (type) {
      case "password":
        return (
          <MuiBox
            className={clsx(
              styles.passwordContanier,
              isMedium && styles.password_contanier_medium
            )}
          >
            <TextField {...props} type={textType} />
            {textType === "text" ? (
              <HideIcon onClick={onTypeChange} {...fieldIconPos} />
            ) : (
              <ShowIcon onClick={onTypeChange} {...fieldIconPos} />
            )}
          </MuiBox>
        );

      case "outlined":
        return (
          <OutlinedInput
            {...props}
            // className={noBorder && styles.outline_input_no_border}
            inputProps={{
              className: {
                notched: noBorder && styles.outline_input_no_border,
              },
              style: { ...(noBorder && { border: "none" }) },
            }}
          />
        );

      default:
        return <TextField {...props} />;
    }
  };
  return getTextFieldByType();
};

MuiTextInputHandler.propTypes = {
  type: PropTypes.string,
  noBorder: PropTypes.bool,
  isMedium: PropTypes.bool,
};

export const MuiTextInput = (props) => {
  const { style, type, status, error, helperText, variant, size } = props;
  const textStatuses = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ];
  const inputStatus = React.useMemo(
    () => (textStatuses.includes(status) ? status : ""),
    [status]
  );
  const isMedium = size === "medium";

  return (
    <MuiTextInputHandler
      {...props}
      style={{ width: "100%", ...style }}
      color={inputStatus || "secondary"}
      type={type}
      status={inputStatus}
      error={error}
      helperText={helperText}
      variant={variant}
      size={size}
      isMedium={isMedium}
      className={styles.text_input}
      InputLabelProps={{
        className: "",
        style: {},
      }}
    />
  );
};

MuiTextInput.defaultProps = {
  variant: "outlined",
  size: "medium",
};

MuiTextInput.propTypes = {
  type: PropTypes.string,
  style: PropTypes.shape(),
  status: PropTypes.string,
  helperText: PropTypes.bool,
  error: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
};

export default MuiTextInput;
