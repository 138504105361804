import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const ACTION_AUTHORITIES = "action_authorities";
export const ACTION_AUTHORITIES_EDIT = "action_authorities_edit";

export const loadActionAuthorities = createAsyncThunk(
  `${ADMIN}/${ACTION_AUTHORITIES}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/action_authorities`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const updateActionAuthorities = createAsyncThunk(
  `${ADMIN}/${ACTION_AUTHORITIES_EDIT}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/action_authorities`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);
