const Auth = {
  // *

  // A

  // B
  back: "Back",
  back_to_login: "Back to login",

  // C
  code: "Code",
  user_permissions: "User permissions",

  // D
  dont_worry_just_type_your_email_and_we_will_send_you_a_recovery_code:
    "Don't worry, just type your email and we will send you a recovery code.",

  // E
  email: "Email",
  edit_permissions: "Edit permissions",

  we_have_sent_you_a_recovery_link_email_please_check_your_mail_box:
    "We have sent you a recovery link email, please check your mail box!",

  // F
  forgot_password: "Forgot Password",
  forgot_your_password: "Forgot your password ?",

  // G
  go_to_login: "Go to login",
  go_to_my_email: "Go to my email",
  // H

  // I

  // J
  just_type_your_new_password_in_the_fields_below:
    "Just type your new password in the fields below",

  // K

  // L
  login: "Login",
  login_to_msm_system: "Login to MSM System",
  logout: "Logout",

  // M

  // N
  new_password: "New Password",

  // O

  // P
  password: "Password",
  password_confirmation: "Password Confirmation",
  profile: "Profile",

  // Q

  // R
  reset_password: "Reset Password",
  ready_to_reset_your_password: "Ready to reset your password ?!",

  // S
  submit: "Submit",
  send: "Send",

  // T

  // U
  user_name: "User name",

  // V

  // W
  welcome_to_msm_system: "Welcome to msm system!",
  please_login_to_our_system_and_enjoy: "please login to our system and enjoy",

  // X

  // Y

  // Z
};

export default Auth;
