import React from "react";
import { ACCESS_TOKEN } from "models";
import { getAuthUser } from "redux/integration/auth/actions";
import PublicLayout from "layouts/public";
import AdminLayout from "layouts/authorized/admin";
import ClientLayout from "layouts/authorized/client";
import SupervisorLayout from "layouts/authorized/supervisor";
import { withRouter } from "hooks";
import { getPageDirection } from "utils/uiHelpers";

const RootRouter = () => {
  const isLogged = !!localStorage?.[ACCESS_TOKEN];
  const userRole = getAuthUser()?.role;

  const authorizedLayouts = {
    super_admin: <AdminLayout />,
    sub_admin: <AdminLayout />,
    client_admin: <ClientLayout />,
    supervisor: <SupervisorLayout />,
  };

  return (
    <div style={{ direction: getPageDirection() }}>
      {isLogged ? authorizedLayouts[userRole] : <PublicLayout />}
    </div>
  );
};

export default withRouter(RootRouter);
