// Import translations
import Auth from "./modules/ar/auth";
import Common from "./modules/ar/common";
import Clients from "./modules/ar/clients";
import Admins from "./modules/ar/admins";

const AR = {
  ...Auth,
  ...Common,
  ...Clients,
  ...Admins,
};

export default AR;
