import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button } from "@mui/material";
import { ButtonLoader } from "components/global/loaders";
import styles from "./styles.module.css";

// color="error"
// color="info"
// color="primary"
// color="secondary"
// color="success"
// color="warning"

export const MuiButton = (props) => {
  const {
    label,
    variant,
    style,
    disabled,
    loading,
    startIcon,
    endIcon,
    size,
    className,
    fitContent = false,
    color,
    themeColor,
    ...rest
  } = props;

  const styling = {
    contained: styles.containedStyle,
    outlined: styles.outlinedStyle,
    text: styles.textStyle,
    disabled: styles.disabledStyle,
  };

  // const theming = {
  // 	error: styles.error_theme,
  // 	info:  styles.info_theme,
  // 	primary:  styles.primary_theme,
  // 	secondary: styles.secondary_theme,
  // 	success: styles.success_theme,
  // 	warning: styles.warning_theme,
  // };

  return loading ? (
    <ButtonLoader />
  ) : (
    <Button
      {...rest}
      variant={variant}
      startIcon={startIcon}
      disabled={disabled}
      endIcon={endIcon}
      size={size}
      className={clsx(
        !themeColor && styling[variant],
        disabled ? styling.disabled : undefined,
        className
      )}
      style={{
        textTransform: "capitalize",
        ...(fitContent && { width: "fit-content" }),
        height: "fit-content",
        color,
        ...style,
      }}
      color={themeColor}
    >
      {label}
    </Button>
  );
};

MuiButton.defaultProps = {
  variant: "contained",
  size: "medium",
};

MuiButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  fitContent: PropTypes.bool,
  style: PropTypes.shape(),
  themeColor: PropTypes.string,
};

export default MuiButton;
