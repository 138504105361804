import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  createSchedulerBranch,
  editSchedulerBranch,
  loadSchedulerBranches,
} from "./actions";

const initialState = {
  fetching: false,
  loading: false,
  schedule_branches: [],
  pagination: {},
};

const slice = createSlice({
  name: "ADMIN",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loadSchedulerBranches.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadSchedulerBranches.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.schedule_branches = action.payload.data?.data?.schedule_branches;
        state.pagination = action.payload.data?.data?.pagination;
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadSchedulerBranches.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addCase(createSchedulerBranch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSchedulerBranch.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createSchedulerBranch.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addCase(editSchedulerBranch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editSchedulerBranch.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(editSchedulerBranch.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
  },
});

export default slice.reducer;
export const { clearInfo } = slice.actions;
