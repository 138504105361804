import React from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import { Text, Spacer, MuiBox } from "atoms";
import { isAr } from "utils/uiHelpers";
import styles from "./styles.module.css";

const MuiMenu = (props) => {
  const { id, MenuListId, anchorEl, onClose, items, ...rest } = props;

  const open = Boolean(anchorEl);

  return (
    <>
      <Menu
        {...rest}
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{ "aria-labelledby": MenuListId }}
        className={styles.mui_menu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ...(isAr()
              ? { ml: "-18px", mt: "0" }
              : { ml: "-32px", mt: "0", minWidth: "100px" }),
            p: "6px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              ...(isAr() ? { left: 20 } : { right: 14 }),
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {items.map((item, index) => {
          return (
            <MuiBox key={index}>
              <MenuItem onClick={item.onClick} className={styles.menu_item}>
                <Text typography="paragraph14" weight="bold">
                  {item.label}
                </Text>
              </MenuItem>
              {index < items.length - 1 && <Spacer height={6} />}
            </MuiBox>
          );
        })}
      </Menu>
    </>
  );
};

MuiMenu.propTypes = {
  id: PropTypes.string,
  MenuListId: PropTypes.string,
  anchorEl: PropTypes.shape(),
  onClose: PropTypes.func,
  items: PropTypes.arrayOf(Object),
};

export default MuiMenu;
