import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const loadSections = createAsyncThunk("GET/SECTION", (params = {}) => {
  const url = `/${API_VERSION_ONE}/admin/sections`;
  const method = "GET";

  return handleAPI(url, params, method);
});

export const createNewSection = createAsyncThunk(
  "CREATE/SECTIONS",
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/sections`;
    const method = "POST";

    return handleAPI(url, params, method);
  }
);

export const updateSection = createAsyncThunk(
  "UPDATE/SECTIONS",
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/sections/${params.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, { section: params.section });
  }
);

export const showSection = createAsyncThunk("get/SHOW_SECTION", (id) => {
  const url = `/${API_VERSION_ONE}/admin/sections/${id}`;
  const method = "GET";

  return handleAPI(url, {}, method);
});

export const deleteSection = createAsyncThunk(`ADMIN/DELETE_SECTION`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/sections/${id}`;

  return handleAPI(url, {}, "DELETE");
});
