import i18n from "translation/i18n";
import { get, isEmpty } from "lodash";
import { saveAs } from "file-saver";
import { getAuthUser } from "redux/integration/auth/actions";
import {
  LOCALE,
  LANG_AR,
  RTL_DIR,
  LTR_DIR,
  USER_ROLES,
  PERMISSION_KEYS,
} from "models";

export const allFileTypes = "image/*, .doc, .docx, .pdf, .xls, .xlsx, .csv";

export const isRtl = localStorage.getItem(LOCALE) === "ar";
export const isAr = () => {
  const { language } = i18n;
  return language === LANG_AR;
};

export const getPageDirection = () => {
  const dir = isAr() ? RTL_DIR : LTR_DIR;
  return dir;
};

// handle it to get specific key
//  const valueGetter = (obj, key) => get(obj, path, '');
export const valueGetter = (obj, path) => get(obj, path, "");

export const handleApiRequestError = (payload) => {
  if (payload?.data?.error) {
    return payload?.data?.error;
  }
  return i18n.t("check_your_connection_please");
};

// export const getAlertInfoFromPayload = (payload) => {
// 	if (payload?.data){
// 		const success = payload?.data.success;
// 		const info = {
// 			open: true,
// 			success,
// 			variant: success ? "success" : "error",
// 			message: success ? payload?.data.message : payload?.data.error,
// 		};
// 		return info;
// 	}
// 	return {
// 		open: true,
// 		message: i18n.t("check_your_connection_please"),
// 		variant: "error",
// 	};
// };

export const handleReloadPage = (ifCase = true, sec = 1) => {
  if (ifCase) {
    setTimeout(() => {
      window.location.reload();
    }, sec * 1000);
  }
};

export const getFieldStatus = (meta) => {
  if (meta.error) {
    return meta.touched ? "error" : undefined;
  }
  return undefined;
};

export const getMenuList = (list) => {
  if (!isEmpty(list)) {
    const newList = list.map((li) => {
      return {
        label: li.name_i18n,
        value: li.id,
      };
    });
    return newList;
  }
  return [];
};

export const getActiveTabByPath = (tabs, path) => {
  let active = 0;
  if (!isEmpty(tabs)) {
    const tabPaths = path?.split("/");
    const lastPath = tabPaths[tabPaths.length - 1];
    tabs.map((tab, index) => {
      if (tab.path.includes(lastPath)) {
        active = index;
      }
    });
  }
  return active;
};

export const prepareListForAPI = (list) => {
  return list.map((da) => {
    return {
      id: da.value,
    };
  });
};

export const prepareListForCheckList = (list) => {
  return list.map(({ id: value, name_i18n: label, ...rest }) => {
    return {
      value,
      label,
      ...rest,
    };
  });
};

export const getLastPathname = (last = 1) => {
  const pathnames = location.pathname.split("/");
  const title = pathnames.slice(pathnames.length - last)[0];
  return title.replace("-", "_").toLowerCase();
};

export const getLastPathnameForTitle = () => {
  const pathnames = location.pathname.split("/");
  const titles = pathnames.slice(pathnames.length - 2);
  if (["report", "branch", "item"].includes(titles[1])) {
    return titles[0].replace("-", "_").toLowerCase();
  }
  return titles[1].replace("-", "_").toLowerCase();
};

export const getColumns = ({
  name: field,
  title: label,
  minWidth = 150,
  ...rest
}) => {
  return {
    field,
    label,
    style: { minWidth },
    ...rest,
  };
};

export const isAnyActionMatchesBuilderCase =
  (...builderCases) =>
  (action) => {
    builderCases.some((builderCase) => {
      return typeof builderCases === "string"
        ? builderCase === action.type
        : builderCase.type === action.type;
    });
  };

export const printPDF = (content) => {
  try {
    const blobURL = URL.createObjectURL(content);
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      });
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const isSuperAdminUser = () => {
  const userRole = getAuthUser()?.role;
  return userRole === USER_ROLES.SUPER_ADMIN;
};

export const isSubAdminUser = () => {
  const userRole = getAuthUser()?.role;
  return userRole === USER_ROLES.SUB_ADMIN;
};

export const isSupervisorUser = () => {
  const userRole = getAuthUser()?.role;
  return userRole === USER_ROLES.SUPERVISOR;
};

export const isSupervisorMindShare = () => {
  const user = getAuthUser();
  return user?.role === USER_ROLES.SUPERVISOR && !user?.client;
};

export const isMindShareAdmin = () => {
  return isSuperAdminUser() || isSubAdminUser() || isSupervisorMindShare();
};

export const isClientAdminUser = () => {
  const userRole = getAuthUser()?.role;
  return userRole === USER_ROLES.CLIENT_ADMIN;
};

export const isClientUser = () => {
  const userRole = getAuthUser()?.role;
  return userRole === USER_ROLES.USER;
};

export const getCurrentRoleKey = () => {
  const userRole = getAuthUser()?.role;
  const roleKeys = {
    super_admin: USER_ROLES.SUPER_ADMIN,
    sub_admin: USER_ROLES.SUB_ADMIN,
    client_admin: USER_ROLES.CLIENT_ADMIN,
    supervisor: USER_ROLES.SUPERVISOR,
  };
  return roleKeys[userRole];
};

export const getAuthorizationData = (componentName) => {
  const user = getAuthUser();
  const authorities = user?.action_authorities;

  if (!isEmpty(authorities)) {
    const authority = authorities.find(
      (auth) => auth.module_name === componentName
    );
    return authority;
  } else {
    return {};
  }
};

export const isAuthorizedComponent = (componentName, permissionKey) => {
  if (isSubAdminUser() || isSupervisorUser()) {
    const authData = getAuthorizationData(componentName);

    if (!isEmpty(authData)) {
      switch (permissionKey) {
        case PERMISSION_KEYS.SHOW:
          return authData.show;

        case PERMISSION_KEYS.CREATE:
          return authData.create;

        case PERMISSION_KEYS.EDIT:
          return authData.edit;

        default:
          return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const disabledButton = (isEdit, pristine, withImage) => {
  if (isEdit) {
    return !withImage && pristine;
  }
  return pristine;
};

export const getSelectedIds = (list = []) => list.map((li) => li.value || li);

export const getRowById = (list = [], id) => list.find((li) => li.id === id);

// export const convertBase64ToFile = (base64String, fileName) => {
// 	let arr = base64String.split(",");
// 	// let mime = arr[0].match(/:(.*?);/)[1];
// 	let bstr = atob(arr[1]);
// 	let n = bstr.length;
// 	let uint8Array = new Uint8Array(n);
// 	while (n--) {
// 		uint8Array[n] = bstr.charCodeAt(n);
// 	}
// 	let file = new File([uint8Array], fileName, {  });
// 	return file;
// };

export const downloadBase64File = (
  base64Data,
  contentType = "png",
  fileName
) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadUrlImage = (path, contentType, fileName) => {
  saveAs(path, fileName);
};

export const statusColor = {
  active: "var(--brand-success-primary)",
  inactive: "var(--brand-warning-primary)",
};

export const requestStatusColor = {
  pending: "var(--brand-warning-primary)",
  approved: "var(--brand-success-primary)",
  rejected: "var(--brand-error-primary)",
  completed: "#4caf50",
};

export const fileUploadStatusColor = {
  processing: "var(--brand-warning-primary)",
  success: "var(--brand-success-primary)",
  faile: "var(--brand-error-primary)",
  partial_sucess: "#4caf50",
};

export const uniqId = () => {
  const start = Date.now().toString(36);
  const last = Math.random().toString(36).substring(2);

  return start + last;
};

export const getImageType = (contentType) =>
  contentType?.split("/")?.at(-1) || "";

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((rs, rj) => {
    reader.onloadend = () => {
      rs(reader.result);
    };
    reader.onerror = rj;
  });
};

export const downloadS3Image = async (link, name) => {
  const s3File = await fetch(link);
  const blob = await s3File.blob();
  const base64 = await blobToBase64(blob);

  saveAs(base64, name);
};
