import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuthUser } from "redux/integration/auth/actions";
import AdminRoutes from "routers/authorized/admin/Admin";
import { MuiBox } from "atoms";
// import HeaderNav from 'components/global/headerNav';
// import SideNav from 'components/global/sideNav';
// import { ContentTemplate } from 'templates';

const Admin = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const isFirstLogin = getAuthUser()?.is_change_password_required;
    if (isFirstLogin) navigate("/reset-password");
  }, []);

  return (
    <MuiBox>
      {/* <Flex>
          <SideNav />
          <Flex flexCol fullWidth>
            <HeaderNav />
            <ContentTemplate> */}
      <AdminRoutes />
      {/* </ContentTemplate>
          </Flex>
        </Flex> */}
    </MuiBox>
  );
};

export default Admin;
