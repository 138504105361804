const ACCESS_TOKEN = "Access-Token";
const USER = "User";
const LOCALE = "Locale";
const ROLE = "role";
const LANG_EN = "en";
const LANG_AR = "ar";
const LTR_DIR = "ltr";
const RTL_DIR = "rtl";

const TEMP_PASS = "********";

const APPLICATION_XML = "text/xml";
const APPLICATION_JSON = "application/json";
const APPLICATION_FORM = "application/x-www-form-urlencoded";

const LOGIN_TYPE = "web";
const API_ACCESS_KEY = "wgqJsI/l9wn2Tj5UGd2lb6Qudf4=";

export {
  ACCESS_TOKEN,
  USER,
  ROLE,
  API_ACCESS_KEY,
  LOGIN_TYPE,
  APPLICATION_XML,
  APPLICATION_JSON,
  APPLICATION_FORM,
  LOCALE,
  LANG_EN,
  LANG_AR,
  LTR_DIR,
  RTL_DIR,
  TEMP_PASS,
};
