import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  loadTypes,
  createNewType,
  updateType,
  deleteType,
  showType,
} from "./actions";

const initialState = {
  fetching: false,
  loading: false,
  igf_types: [],
  igf_type: {},
  pagination: {},
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  reducers: {
    resetType: (state) => {
      state.igf_type = {};
    },
  },
  extraReducers: (builder) => {
    // IGF types list
    builder.addCase(loadTypes.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadTypes.fulfilled, (state, action) => {
      state.fetching = false;
      state.igf_types = action.payload.data?.data?.igf_types || [];
      state.pagination = action.payload.data?.data?.pagination || {};
    });
    builder.addCase(loadTypes.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
    // Show type
    builder.addCase(showType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showType.fulfilled, (state, action) => {
      state.loading = false;
      state.igf_type = action.payload.data?.data?.igf_type || {};
    });
    builder.addCase(showType.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Create Type
    builder.addCase(createNewType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewType.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createNewType.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Type
    builder.addCase(updateType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateType.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateType.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete Type
    builder.addCase(deleteType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteType.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteType.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
  },
});

export default slice.reducer;

export const { resetType } = slice.actions;
