import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  downloadBulkSampleFile,
  downloadFileError,
  downloadUploadedFile,
  loadBulkImportList,
  uploadBulkFile,
} from "./actions";

const initialState = {
  fetching: false,
  loading: false,
  bulk_imports: [],
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // bulk list
    builder.addCase(loadBulkImportList.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadBulkImportList.fulfilled, (state, action) => {
      state.fetching = false;
      state.bulk_imports = action.payload.data?.data.bulk_imports || [];
    });
    builder.addCase(loadBulkImportList.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // bulk list
    builder.addCase(downloadBulkSampleFile.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(downloadBulkSampleFile.fulfilled, (state) => {
      state.fetching = false;
    });
    builder.addCase(downloadBulkSampleFile.rejected, (state) => {
      state.fetching = false;
    });

    // download
    builder.addCase(downloadUploadedFile.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(downloadUploadedFile.fulfilled, (state) => {
      state.fetching = false;
    });
    builder.addCase(downloadUploadedFile.rejected, (state) => {
      state.fetching = false;
    });

    // File Error
    builder.addCase(downloadFileError.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(downloadFileError.fulfilled, (state) => {
      state.fetching = false;
    });
    builder.addCase(downloadFileError.rejected, (state) => {
      state.fetching = false;
    });

    // Upload File
    builder.addCase(uploadBulkFile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadBulkFile.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(uploadBulkFile.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
  },
});

export default slice.reducer;

export const { resetBranchGroup } = slice.actions;
