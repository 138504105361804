import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const DASHBOARD = "dashboard";

export const loadUserDashboard = createAsyncThunk(
  `${ADMIN}/${DASHBOARD}`,
  (user_id) => {
    const url = `/${API_VERSION_ONE}/admin/clients/${user_id}/dashboard`;
    const method = "GET";

    return handleAPI(url, {}, method);
  }
);
