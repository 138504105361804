import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Tabs, Tab } from "@mui/material";
import styles from "./styles.module.css";

const MuiTabs = ({
  withBackground,
  withSpace,
  centered,
  className,
  active,
  tabs,
  onChange,
  ...rest
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Tabs
        className={clsx(
          styles.tabs,
          withBackground && styles.withBackground,
          withSpace && styles.spacing,
          className
        )}
        value={active}
        onChange={(e, index) => {
          onChange(index, tabs[index], e);
        }}
        variant={!centered && "scrollable"}
        centered={centered}
        fullwidth="true"
        scrollButtons="auto"
        {...rest}
      >
        {tabs?.map((tab) => (
          <Tab
            className={styles.tab}
            key={tab.value}
            label={tab.label}
            data-url={tab.value}
            data-testid={tab.testId}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
    </Box>
  );
};

MuiTabs.propTypes = {
  type: PropTypes.string,
  withBackground: PropTypes.bool,
  withSpace: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(Object),
  onChange: PropTypes.func,
};

export default MuiTabs;
