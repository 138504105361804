import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  createBrand,
  deleteBrand,
  loadBrands,
  updateBrand,
} from "./actions";

const initialState = {
  fetching: false,
  loading: false,
  brands: [],
  pagination: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  extraReducers: (builder) => {
    // Brands list
    builder.addCase(loadBrands.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadBrands.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.brands = action.payload.data?.data.brands;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadBrands.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Create brands
    builder.addCase(createBrand.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBrand.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createBrand.rejected, (state, action) => {
      state.loading = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update brands
    builder.addCase(updateBrand.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBrand.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateBrand.rejected, (state, action) => {
      state.loading = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete brands
    builder.addCase(deleteBrand.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteBrand.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteBrand.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.brands = [];
      state.message = "";
    });
  },
});

export default slice.reducer;
