import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  loadClients,
  validateClientSteps,
  createClient,
  loadLimitations,
  showClient,
  updateClient,
  deleteClient,
} from "./actions";

const initialState = {
  fetching: false,
  clients: [],
  client: {},
  limitations: [],
  pagination: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  extraReducers: (builder) => {
    // Clients
    builder.addCase(loadClients.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadClients.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.clients = action.payload.data?.data?.clients;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadClients.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Show Client
    builder.addCase(showClient.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(showClient.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.client = action.payload.data?.data?.client;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(showClient.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Validate client steps
    builder.addCase(validateClientSteps.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(validateClientSteps.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(validateClientSteps.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.clients = [];
      state.message = "";
    });

    // Limitations steps
    builder.addCase(loadLimitations.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadLimitations.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        const limits = action.payload.data?.data?.limitations;
        state.limitations = limits?.map((limit) => {
          return {
            ...limit,
            limit: { [limit.name_en]: { count: limit.count } },
          };
        });
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadLimitations.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.clients = [];
      state.message = "";
    });

    // Create Client
    builder.addCase(createClient.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createClient.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Client
    builder.addCase(updateClient.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete Client
    builder.addCase(deleteClient.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteClient.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.clients = [];
      state.message = "";
    });
  },
});

export default slice.reducer;
