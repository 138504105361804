import { createSlice } from "@reduxjs/toolkit";
// import { Toastr } from "components/global/toasterHandler/Toastr";
// import { handleApiRequestError } from "utils/uiHelpers";
import { exportLists } from "./actions";

const initialState = {
  fetching: false,
};

const slice = createSlice({
  name: "ADMIN",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(exportLists.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(exportLists.fulfilled, (state) => {
      state.fetching = false;
    });
    builder.addCase(exportLists.rejected, (state) => {
      state.fetching = false;
    });
  },
});

export default slice.reducer;
