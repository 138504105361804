import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import { ADMIN, loadUserDashboard } from "./actions";

const initialState = {
  dashboards: [],
  fetching: false,
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  extraReducers: (builder) => {
    // Dashboard Information

    builder.addCase(loadUserDashboard.pending, (state) => {
      state.fetching = true;
    });

    builder.addCase(loadUserDashboard.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.dashboards = action.payload.data?.data;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });

    builder.addCase(loadUserDashboard.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.dashboards = [];
      state.message = "";
    });
  },
});

export default slice.reducer;
