import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const loadBranchGroups = createAsyncThunk(
  "get/branch_groups",
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/branch_groups`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const createNewGroup = createAsyncThunk(
  "create/group",
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/branch_groups`;
    const method = "POST";

    return handleAPI(url, params, method);
  }
);

export const updateGroup = createAsyncThunk(
  "update/branch_groups",
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/branch_groups/${params.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, { branch_group: params.branch_group });
  }
);

export const showGroup = createAsyncThunk("get/showGroup", (id) => {
  const url = `/${API_VERSION_ONE}/admin/branch_groups/${id}`;
  const method = "GET";

  return handleAPI(url, {}, method);
});

export const deleteBranchGroup = createAsyncThunk(`ADMIN/DELETE_TYPE`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/branch_groups/${id}`;

  return handleAPI(url, {}, "DELETE");
});
