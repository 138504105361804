import React from "react";
import i18n from "i18next";
import { initReactI18next, Trans } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EN from "./en";
import AR from "./ar";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    nsSeparator: false,
    resources: {
      en: { translation: EN },
      ar: { translation: AR },
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "Locale",
      caches: ["localStorage"],
    },
  });

const i18nTrans = (text, values, components) => {
  return <Trans i18nKey={text} values={values} components={components} />;
};

export default i18n;

export { i18nTrans };
