const Clients = {
  // *

  // A
  active: "Active",

  apply_default_permission: "Apply default permission",
  apply_limitations: "Apply Limitations ?",
  address_en: "Address en",
  address_ar: "Address ar",
  are_you_sure_you_want_to_delete_this_branch:
    "Are you sure you want to delete this branch <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_brand:
    "Are you sure you want to delete this brand <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_item:
    "Are you sure you want to delete this item <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_user:
    "Are you sure you want to delete this user <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_city:
    "Are you sure you want to delete this city <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_delete_inventory_notification:
    "Are you sure you want to delete this inventory notification that reported by <b>{{name}}</b> at <b>{{date}}</b>",
  add_image_for_item: "Add image for item",
  attendance_records_reports: "Attendance records report",

  // B
  brand: "Brand",
  brands: "Brands",
  the_branch: "Branch",
  branch: "Branch",
  the_branches: "Branches",
  branches: "Branches",
  branch_name: "Branch name",
  brands_count: "Brands count",
  branches_count: "Branches count",
  brand_name: "Brand name",
  barcode_number: "Barcode number",
  branch_group: "Branch group",
  branches_group: "Branches group",

  // C
  client: "Client",
  clients: "Clients",
  cities: "Cities",
  city: "City",
  cities_count: "Cities count",
  client_details: "Client details",
  client_admin: "Client's admin",
  create_brand: "Create brand",
  create_branch: "Create branch",
  create_item: "Create item",
  create_user: "Create user",
  create_city: "Create city",
  city_name: "City name",
  client_reports: "Client reports",
  count: "Count",
  client_name_ar: "Client name (AR)",
  client_name_en: "Client name (EN)",

  component: "Component",
  create: "Create",
  content: "Content",
  content_ar: "Content ar",
  create_new_type: "Create new Type",
  create_new_group: "Create new Group",
  create_new_section: "Create new Section",

  // D
  dashboard: "Dashboard",
  description: "Description",
  delete_branch: "Delete branch",
  delete_brand: "Delete brand",
  delete_item: "Delete item",
  delete_city: "Delete city",
  delete_user: "Delete user",
  daily_reports: "Dialy reports",
  delete_client: "Delete client",

  // E
  email: "Email",
  edit_permission: "Edit permission",

  // F
  // file_downloaded: "File {{name}} downloaded successfully!",
  file_downloaded: "Report file downloaded successfully!",
  filter_by_user_code: "Filter by user code",
  filter_by_range_of_date: "Filter by range of date",
  filter_by_date: "Filter by date",

  // G
  gram: "Gram",

  // H

  // I
  inactive: "Inactive",
  inventory_reports: "Inventory reports",
  igf_reports: "IGF reports",
  igf_reports_count: "IGF reports count",
  igf_item_reports: "IGF item reports",
  items: "Items",
  item: "Item",
  items_count: "Items count",
  item_name: "Item name",

  initialize_clients_info: "Initialize client's info",
  initialize_clients_admin: "Initialize client's admin",
  initialize_limitation: "Initialize limitation",
  inventory_notifications_reports: "Inventry Notification Reports",
  delete_inventory_notification: "Delete Inventory Notification",

  // J

  // K
  kilogram: "Kilogram",

  // L
  list: "List",
  last_action: "Last action",
  liter: "Liter",
  loading_permissions: "Loading permissions ...",

  // M
  milliliter: "Milliliter",
  manage_types: "Manage Types",

  // N
  next_step: "Next step",
  new_client: "New client",
  new_branch: "New branch",
  new_brand: "New brand",
  new_item: "New item",
  new_user: "New user",
  new_city: "New city",

  no_branches: "No branches",
  no_users: "No users",
  no_items: "No items",
  no_cities: "No cities",
  no_clients: "No clients",
  no_brands: "No brands",
  no_types: "No Types",
  no_branches_group: "No branches group",
  notification: "Notification",
  no_list_available: "No list available",
  no_limitations: "No limitations",

  notification_filtered_branches_list_title: "Filtered notification branches",
  notification_filtered_users_list_title: "Filtered notification users",
  notification_filtered_cities_list_title: "Filtered notification cities",
  notification_filtered_brands_list_title: "Filtered notification brands",
  notification_filtered_products_list_title: "Filtered notification products",
  notification_filtered_uploaded_from_count_list_title:
    "Filtered notification uploaded from count",
  notification_filtered_uploaded_by_count_list_title:
    "Filtered notification uploaded by count",

  // O
  other: "Other",

  // P
  products: "Products",
  products_count: "Products count",
  previous_step: "Previous Step",

  // Q
  quantity: "Quantity",

  // R
  report: "Report",
  reports: "Reports",
  reporter: "Reporter",
  reports_count: "Reports count",
  request_report: "Request a report",
  report_number: "Report number",
  rtv_reports: "RTV reports",
  reports_view: "Reports view",

  // S
  sdr_reports_count: "SDR reports count",
  sdr_reports: "SDR reports",
  slug: "Slug",
  slug_ar: "Slug (AR)",
  size_unit: "Size type",
  selected_of: "Selected <b>{{selected}}</b> of <b>{{of}}</b> {{data}}",
  select_branch: "Select branch",
  select_user: "Select user",
  select_city_to_load_the_branches: "Select another city to load its branches",
  search_branches: "Search branches",
  search_users: "Search users",
  select_city: "Select city",
  search_cities: "Search cities",
  select_item: "Select item",
  search_items: "Search items",
  sdr_reports_reports: "SDR reports",
  select_client: "Selec client",
  search_clients: "Search clients",
  select_brand: "Select brand",
  search_brands: "Search brands",
  select_type: "Select Type",
  search_type: "Search Type",
  select_branch_group: "Select branch group",
  search_branch_group: "Search branch group",
  select_section: "Select section",
  search_section: "Search section",
  show_users_who_will_be_notified: "Show users who will be notified",
  send_notification: "Send notification",
  section: "Section",
  sections: "Sections",
  show_based_on_user: "Show based on User",

  // T
  type_client_slug: "Type client's slug",
  type_client_slug_ar: "Type client's slug (AR)",
  type_client_user_name: "Type client's user name",
  type_client_password: "Type client's password",
  type_notification_content: "Type notification content",
  type: "Type",
  types: "Types",

  // U
  users: "Users",
  users_count: "Users count",

  update: "Update",
  update_client: "Update client",
  update_branch: "Update branch",
  update_brand: "Update brand",
  update_item: "Update item",
  update_user: "Update user",
  update_city: "Update city",
  uploaded_from_count: "Uploaded from count",
  uploaded_by_count: "Uploaded by count",
  update_type: "Update Type",
  update_group: "Update Group",
  update_section: "Update Section",
  users_view: "Users view",

  // V
  visiting_reports: "Visiting report",
  view: "View",

  // W
  who_should_be_notified: "Who should be notified ?",

  // X

  // Y
  you_have_to_submit_the_previous_step_first:
    "You have to submit the previous step first",

  // Z
};

export default Clients;
