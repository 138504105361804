import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, downloadAPI } from "utils/apiUtils";

export const exportLists = createAsyncThunk(
  `ADMIN/EXPORT_LISTS`,
  ({ extension = "", report_type = "", ...params }) => {
    const url = `/${API_VERSION_ONE}/admin/${report_type}${extension}`;

    return downloadAPI(url, `${report_type}${extension}`, {
      ...params,
    });
  }
);
