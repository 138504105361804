import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "redux/store";
import ErrorBoundary from "components/global/error-boundary";
import { FullScreenLoader } from "components/global/loaders";
import "styles/styleTokens.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <React.Suspense fallback={<FullScreenLoader />}>
      <Provider store={store}>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </Provider>
    </React.Suspense>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
