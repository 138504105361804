const Auth = {
  // *

  // A

  // B
  back: "رجوع",
  back_to_login: "العودة الي صفحة تسجيل الدخول",

  // C
  code: "الكود",
  user_permissions: "صلاحيات المستخدم",

  // D
  dont_worry_just_type_your_email_and_we_will_send_you_a_recovery_code:
    "لا داعي للقلق ، ما عليك سوى كتابة بريدك الإلكتروني وسنرسل إليك رمز الاسترداد.",

  // E
  email: "ايميل",
  edit_permissions: "تعديل الصلاحيات",
  we_have_sent_you_a_recovery_link_email_please_check_your_mail_box:
    "لقد أرسلنا إليك بريدًا إلكترونيًا لرابط الاسترداد ، يرجى التحقق من صندوق بريدك الالكتروني!",

  // F
  forgot_password: "استرداد كلمة المرور",
  forgot_your_password: "نسيت كلمة المرور ؟",

  // G
  go_to_login: "اذهب الى صفحة تسجيل الدخول",
  go_to_my_email: "اذهب الى بريدي الالكتروني",
  // H

  // I

  // J
  just_type_your_new_password_in_the_fields_below:
    "ما عليك سوى كتابة كلمة المرور الجديدة في الحقول أدناه",

  // K

  // L
  login: "تسجيل الدخول",
  login_to_msm_system: "تسجيل الدخول في نظام ام اس ام",
  logout: "تسجيل الخروج",

  // M

  // N
  new_password: "كلمة مرور جديدة",

  // O

  // P
  password: "كلمة المرور",
  password_confirmation: "تأكيد كلمة المرور",
  profile: "الملف الشخصي",

  // Q

  // R
  reset_password: "استعادة كلمة المرور",
  ready_to_reset_your_password:
    "هل أنت جاهز لإعادة تعيين كلمة المرور الخاصة بك ؟!",

  // S
  submit: "تأكيد",
  send: "ارسال",

  // T

  // U
  user_name: "اسم المستخدم",

  // V

  // W
  welcome_to_msm_system: "مرحباً بك في نظام ام اس ام",
  please_login_to_our_system_and_enjoy:
    "يرجى تسجيل الدخول إلى نظامنا والاستمتاع",

  // X

  // Y

  // Z
};

export default Auth;
