const Clients = {
  // *

  // A
  active: "نشط",
  apply_default_permission: "تطبيق الإذن الافتراضي",
  apply_limitations: "تطبيق المحدوديات ؟",
  address_en: "العنوان بالانجليزية",
  address_ar: "العنوان بالعربية",

  are_you_sure_you_want_to_delete_this_branch:
    "هل أنت متأكد أنك تريد حذف هذا الفرع <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_brand:
    "هل أنت متأكد أنك تريد حذف هذا العلامة التجارية <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_item:
    "هل أنت متأكد أنك تريد حذف هذا المنتج <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_user:
    "هل أنت متأكد أنك تريد حذف هذا المستخدم <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_city:
    "هل أنت متأكد أنك تريد حذف هذا المدينة <b>{{name}}</b>",
  are_you_sure_you_want_to_delete_this_delete_inventory_notification:
    "هل أنت متأكد أنك تريد حذف اشعار الجرد الذي تم انشاءه بواسطة <b>{{name}}</b> في <b>{{date}}</b>",
  add_image_for_item: "اضف صورة للمنتج",
  attendance_records_reports: "تقرير سجلات الحضور",

  // B
  brand: "العلامة التجارية",
  brands: "العلامات التجارية",
  the_branch: "الفرع",
  branch: "فرع",
  the_branches: "الفروع",
  branches: "فروع",
  branch_name: "اسم الفرع",
  brands_count: "عدد العلامات التجارية",
  branches_count: "عدد الفروع",
  brand_name: "اسم العلامة التجارية",
  barcode_number: "رقم الباركود",
  branch_group: "مجموعه الفروع",
  branches_group: "مجموعه الفروع",

  // C
  client: "عميل",
  clients: "العملاء",
  cities: "المدن",
  city: "المدينة",
  cities_count: "عدد المدن",
  client_details: "تفاصيل العميل",
  client_admin: "مدير العميل",
  create_brand: "انشاء علامة تجارية",
  create_branch: "انشاء فرع",
  create_item: "انشاء منتج",
  create_user: "انشاء مستخدم",
  create_city: "انشاء مدينة",
  city_name: "اسم المدنية",
  count: "العدد",
  component: "المحتوى",
  create: "انشاء",
  client_reports: "تقارير العميل",
  content: "المحتوى",
  content_ar: "المحتوى بالعربي",
  client_name_ar: "اسم العميل بالعربي",
  client_name_en: "اسم العميل بالانجليزي",
  create_new_type: "انشاء نوع جديد",
  create_new_group: "انشاء مجموعه جديدة",
  create_new_section: "انشاء قسم جديد",
  // D
  dashboard: "الاحصائيات",
  description: "الوصف",
  delete_branch: "حذف الفرع",
  delete_brand: "حذف العلامة التجارية",
  delete_item: "حذف المنتج",
  delete_city: "حذف المدينة",
  delete_user: "حذف المستخدم",
  daily_reports: "التقارير اليومية",
  delete_client: "حذف عميل",

  // E
  email: "الايميل",
  edit_permission: "تعديل الصلاحيات",

  // F
  // file_downloaded: "الملف {{name}} تم تحميله بنجاح!",
  file_downloaded: "تم تحميل ملف التقرير بنجاح!",
  filter_by_user_code: "البحث عن طريق كود المستخدم",
  filter_by_range_of_date: "حدد التاريخ للبحث",
  filter_by_date: "حدد التاريخ للبحث",

  // G
  gram: "جرام",

  // H

  // I
  inactive: "غير نشط",
  items: "المنتجات",
  item: "منتج",
  items_count: "عدد المنتجات",
  inventory_reports: "تقارير الجرد",
  igf_reports: "IGF تقارير",
  igf_reports_count: "عدد تقارير IGF",
  igf_item_reports: "IGF تقارير المنتج",
  item_name: "اسم المنتج",

  initialize_clients_info: "تهيئة معلومات العميل",
  initialize_clients_admin: "تهيئة مسؤول العميل",
  initialize_limitation: "تهيئة الحدود",
  inventory_notifications_reports: "تقارير تنبيهات الجرد",
  delete_inventory_notification: "حذف اشعار الجرد",

  // J

  // K
  kilogram: "كيلو جرام",

  // L
  list: "القائمة",
  last_action: "آخر نشاط",
  liter: "لتر",
  loading_permissions: "تحميل الصلاحيات ...",

  // M
  milliliter: "ملي لتر",
  manage_types: "اداره الآنواع",
  // N
  next_step: "الخطوة التالية",
  new_client: "عميل جديد",
  new_branch: "فرع جديد",
  new_brand: "علامة تجارية جديدة",
  new_item: "منتج جديد",
  new_user: "مستخدم جديد",
  new_city: "مدينة جديدة",

  no_branches: "لا توجد فروع",
  no_users: "لا يوجد مستخدمين",
  no_items: "لا توجد منتجات",
  no_cities: "لا توجد مدن",
  no_clients: "لا يوجد عملاء",
  no_brands: "لا توجد علامات تجارية",
  no_types: "لا توجد آنواع",
  no_branches_group: "لا توجد مجموعات فروع",
  notification: "اشعارات",
  no_list_available: "لا توجد بيانات",
  no_limitations: "لا توجد حدود",

  notification_filtered_branches_list_title: "Filtered notification branches",
  notification_filtered_users_list_title: "Filtered notification users",
  notification_filtered_cities_list_title: "Filtered notification cities",
  notification_filtered_brands_list_title: "Filtered notification brands",
  notification_filtered_products_list_title: "Filtered notification products",
  notification_filtered_uploaded_from_count_list_title:
    "Filtered notification uploaded from count",
  notification_filtered_uploaded_by_count_list_title:
    "Filtered notification uploaded by count",

  // O
  other: "أخرى",

  // P
  products: "المنتجات",
  products_count: "عدد المنتجات",
  previous_step: "الخطوة االسابقة",

  // Q
  quantity: "الكمية",

  // R
  report: "تقرير",
  reports: "تقارير",
  reporter: "مضيف التقارير",
  reports_count: "عدد التقارير",
  request_report: "طلب تقرير",
  report_number: "رقم التقرير",
  rtv_reports: "تقارير RTV",
  reports_view: "عرض حسب التقارير",
  // S
  sdr_reports_count: "عدد تقرير المنتجات المقاربه للانتهاء",
  sdr_reports: "تقرير المنتجات المقاربه للانتهاء",

  slug: "السلق",
  slug_ar: "السلق بالعربي",
  size_unit: "نوع الحجم",
  selected_of: "تم اختيار <b>{{selected}}</b> من <b>{{of}}</b> {{data}}",
  select_branch: "اختر فرع",
  select_city_to_load_the_branches: "اختر مدينة اخرى لعرض الفروع المتوفرة فيها",
  search_branches: "البحث عن فروع",
  search_users: "البحث عن المستخدمين",
  select_city: "اختر مدينة",
  search_cities: "البحث عن المدن",
  select_item: "اختر منتج",
  search_items: "البحث عن منتجات",
  sdr_reports_reports: "تقرير المنتجات المقاربه للانتهاء",
  select_user: "اختر مستخدم",
  search_user: "ابحث عن مستخدم",
  select_client: "اختر عميل",
  search_clients: "ابحث عن عميل",
  select_brand: "اختر علامة تجارية",
  select_type: "اختر نوع",
  search_brands: "ابحث عن علامة تجارية",
  search_type: "ابحث عن نوع",
  select_branch_group: "اختر مجموعه فرع",
  search_branch_group: "البحث عن مجموعه فرع",
  select_section: "اختر قسم",
  search_section: "البحث عن قسم",
  show_users_who_will_be_notified:
    "عرض المستخدمين الذين سيتم ارسال اشعارات لهم",
  send_notification: "ارسال الاشعار",
  section: "القسم",
  sections: "الآقسام",
  show_based_on_user: "عرض على حسب المستخدم",

  // T
  type_client_slug: "اكتب سلق العميل",
  type_client_slug_ar: "اكتب سلق العميل بالعربي",
  type_client_user_name: "اكتب اسم مستخدم العميل",
  type_client_password: "اكتب كلمة مرور العميل",
  type_notification_content: "اكتب محتوى الاشعار",
  type: "نوع",
  types: "آنواع",

  // U
  users: "المستخدمين",
  users_count: "عدد المستخدمين",

  update: "تحديث",
  update_client: "تحديث العميل",
  update_branch: "تحديث الفرع",
  update_brand: "تحديث العلامة التجارية",
  update_item: "تحديث المنتج",
  update_user: "تحديث المستخدم",
  update_city: "تحديث المدينة",
  uploaded_from_count: "تم الرفع من",
  uploaded_by_count: "تم الرفع بواسطة",
  update_type: "تحديث النوع",
  update_group: "تحديث المجموعه",
  update_section: "القسم المجموعه",
  users_view: "عرض حسب المستخدمين",

  // V
  visiting_reports: "تقرير الزيارات",
  view: "العرض",

  // W
  who_should_be_notified: "من سيتم اشعاره ؟",

  // X

  // Y
  you_have_to_submit_the_previous_step_first:
    "يجب عليك اكمال الخطوة السابقة أولاً",

  // Z
};

export default Clients;
