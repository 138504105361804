import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
const ADMINS = "admins";
const CREATE_ADMIN = "create_admin";
const UPDATE_ADMIN = "update_admin";
const DELETE_ADMIN = "delete_admin";

export const loadAdmins = createAsyncThunk(
  `${ADMIN}/${ADMINS}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/admins`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const createAdmin = createAsyncThunk(
  `${ADMIN}/${CREATE_ADMIN}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/admins`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateAdmin = createAsyncThunk(
  `${ADMIN}/${UPDATE_ADMIN}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/admins/${data.user.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteAdmin = createAsyncThunk(
  `${ADMIN}/${DELETE_ADMIN}`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/admins/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method);
  }
);
