import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const ITEMS = "items";
export const CREATE_ITEM = "create_item";
export const UPDATE_ITEM = "update_item";
export const DELETE_ITEM = "delete_item";

export const loadItems = createAsyncThunk(
  `${ADMIN}/${ITEMS}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/items`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const createItem = createAsyncThunk(
  `${ADMIN}/${CREATE_ITEM}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/items`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateItem = createAsyncThunk(
  `${ADMIN}/${UPDATE_ITEM}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/items/${data.item.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteItem = createAsyncThunk(`${ADMIN}/${DELETE_ITEM}`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/items/${id}`;
  const method = "DELETE";

  return handleAPI(url, {}, method);
});
