import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Text } from "atoms";
import styles from "./styles.module.css";

const defaultOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
];

const CustomSwitcher = (props) => {
  const {
    disabled,
    defaultChecked,
    options = defaultOptions,
    onChange,
  } = props;
  const [optIndex, setOptIndex] = React.useState(0);

  const handleClick = () => {
    const ind = optIndex === 0 ? 1 : 0;
    setOptIndex(ind);
    onChange(options[ind]);
  };

  React.useEffect(() => {
    if (defaultChecked) {
      const currentOption = options
        .map((opt, i) => (opt.value === defaultChecked ? i : null))
        .filter(Boolean)[0];
      setOptIndex(currentOption || 0);
    }
  }, [defaultChecked]);

  return (
    <span
      className={clsx(styles.root)}
      onClick={handleClick}
      disabled={disabled}
    >
      <span className={clsx(styles.track)}>
        <span
          className={clsx(
            styles.thumb,
            optIndex === 0 ? styles.thumb_1 : styles.thumb_2
          )}
        >
          <Text typography="caption12">{options[optIndex].label}</Text>
        </span>
      </span>
    </span>
  );
};

CustomSwitcher.propTypes = {
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(Object),
};

export default CustomSwitcher;
