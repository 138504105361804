import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const BRANDS = "brands";
export const CREATE_BRAND = "create_brand";
export const UPDATE_BRAND = "update_brand";
export const DELETE_BRAND = "delete_brand";

export const loadBrands = createAsyncThunk(
  `${ADMIN}/${BRANDS}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/brands`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const createBrand = createAsyncThunk(
  `${ADMIN}/${CREATE_BRAND}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/brands`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateBrand = createAsyncThunk(
  `${ADMIN}/${UPDATE_BRAND}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/brands/${data.brand.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteBrand = createAsyncThunk(
  `${ADMIN}/${DELETE_BRAND}`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/brands/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method);
  }
);
