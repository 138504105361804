import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";

const MuiLoader = ({ type, color, fixed, ...rest }) => {
  const progressType = {
    circle: <CircularProgress {...rest} />,
  };

  const loaderType = progressType[type] || progressType["circle"];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        ...(fixed && {
          background: "rgba(241, 243, 245, 0.6)",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 9999,
        }),
      }}
      color={color}
    >
      {loaderType}
    </Box>
  );
};

MuiLoader.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  fixed: PropTypes.bool,
};

export default MuiLoader;
