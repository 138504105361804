import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  from_route: false,
  index: 0,
};

const stateSlice = createSlice({
  name: "custom_state",
  initialState,
  reducers: {
    setCustomStateFromRoute: (state, action) => {
      state.from_route = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
  },
});

export default stateSlice.reducer;
export const { setCustomStateFromRoute, setIndex } = stateSlice.actions;
