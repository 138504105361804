import axios from "axios";
import Qs from "qs";
import FileSaver from "file-saver";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { API_ROOT } from "env";
import {
  // ACCESS_TOKEN,
  // APPLICATION_FORM,
  APPLICATION_JSON,
  API_ACCESS_KEY,
  // APPLICATION_XML,
  LOCALE,
} from "models";
import errorResponseHandler from "utils/errorHandler";
import { ACCESS_TOKEN } from "models";
import i18n from "translation/i18n";
// import _ from 'lodash';

const API_VERSION_ONE = "v1";

const http = (config) =>
  axios(config)
    .then((response) => response)
    .catch((res) => res.response);

axios.interceptors.response.use((response) => response, errorResponseHandler);

export const serializerConfig = {
  arrayFormat: "brackets",
  encode: false,
};

function removeEmptyParams(params) {
  // use lodash here
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
}

function handleAPI(path, params, method, data, cacheConfig = {}, options = {}) {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const locale = localStorage.getItem(LOCALE) || "en";
  const client_id = localStorage.getItem("client_id");

  // isThirdPartyUrl = false,
  let headers = {
    "Content-Type": APPLICATION_JSON,
    Accept: APPLICATION_JSON,
    "API-ACCESS-KEY": API_ACCESS_KEY,
    "ACCESS-TOKEN": token,
    ...(client_id && { "CLIENT-ID": client_id }),
    locale,
  };

  let url = `${API_ROOT}${path}`;

  // if (isThirdPartyUrl) {
  //   url = path;
  //   headers = {
  // 'ACCESS-TOKEN': token,
  //   };
  // }

  const { cache } = cacheConfig;

  const config = {
    method,
    url,
    params,
    paramsSerializer: (paramObject) =>
      Qs.stringify(paramObject, serializerConfig),
    data: JSON.stringify(data),
    headers,
    cache,
    ...options,
  };

  return http(config);
}

function downloadAPI(path, fileName, params = {}) {
  const qs = `?${Qs.stringify(params, serializerConfig)}`;

  const token = localStorage.getItem(ACCESS_TOKEN);
  const locale = localStorage.getItem(LOCALE) || "en";
  const client_id = localStorage.getItem("client_id");

  const promise = new Promise((resolve, reject) => {
    fetch(API_ROOT + path + qs, {
      headers: {
        Accept: APPLICATION_JSON,
        "Content-Type": APPLICATION_JSON,
        "API-ACCESS-KEY": API_ACCESS_KEY,
        "ACCESS-TOKEN": token,
        ...(client_id && { "CLIENT-ID": client_id }),
        locale,
      },
      responseType: "blob",
    })
      .then((response) => {
        if (!response.ok) {
          return response.json();
        }
        return response.blob();
      })
      .then((data) => {
        if (data instanceof Blob) {
          const extension = data.type.split("/")[1];
          // we should not pass the extension with file name, that should extract form Blom it self
          const withExtension =
            fileName.split(".").length >= 2
              ? fileName
              : `${fileName}.${extension}`;
          FileSaver.saveAs(data, withExtension);
          Toastr.success(i18n.t("file_downloaded"));
          resolve();
        }
        if (data.error) {
          Toastr.error(data.error);
          reject(data);
        }
      })
      .catch((err) => {
        Toastr.error(err);
        reject(err);
      });
  });

  return promise;
}

export { handleAPI, downloadAPI, removeEmptyParams, API_VERSION_ONE };
