const Common = {
  // *
  en: "EN",
  ar: "ع",

  // A
  app_title: "نظام مايند شير سيستم",
  app_title_shortcut: "MSM System",
  actions: "العمليات",
  allow_to_clock_offsite: "السماح بالبصمه عن طريق الموقع الجغرافي",
  all: "الكل",
  are_you_sure: "هل أنت متاكد؟",
  are_you_sure_to_delete_item: "هل أنت متأكد من حذف هذا العنصر؟",
  action_by: "عملية بواسطة",
  approved: "معتمدة",
  approve: "اعتماد",
  action_at: "وقت الآجراء",

  // B
  bulk_upload: "رفع مجموعة",
  branch_number: "رقم الفرع",
  by: "بواسطه",
  bulk_import: "استيراد مجموعه",
  // C
  close: "اغلاق",
  cancel: "إلغاء الامر",
  comming_soon: "قريباً",
  check_your_connection_please:
    "هناك خطأ ما في اتصالك بالإنترنت ، أو أن الخادم الخاص بك غير متصل ، يرجى مراجعة المسؤول!",
  clear_filters: "ازالة الفلاتر",
  creator_name: "اسم المنشئ",
  created_at: "وقت الآنشاء",
  creator_name_or_action_by_name: "اسم المنشئ او اسم صاحب الآجراء",
  completed: "مكتمل",
  comment: "تعليق",

  // D
  details: "تفاصيل",
  delete: "حذف",
  download_image: "تحميل الصوره",
  delete_image: "حذف الصوره",
  download_all_images: "تحميل كل الصور",
  download_uploaded_file: "تحميل الملف المرفوع",
  download_sample: "تحميل نسخه",
  download_errors: "تحميل الاخطاء",
  drag_files_or_click_to_select: "اسحب الملفات أو انقر للتحديد",

  // E
  edit: "تعديل",
  excel: "Excel",
  export: "تصدير",
  email_msg: "يجب أن يكون النص بتنسيق بريد إلكتروني",
  expiry_date: "تاريخ الإنتهاء",
  error_while_loading_the_reports: "حدث خطأ اثناء تحميل التقارير",
  error: "خطأ",
  edit_igf_report: "تعديل تقرير IGF",
  edit_sdr_report: "تعديل تقرير SDR",
  edit_rtv_report: "تعديل تقرير RTV",
  edit_inventory_report: "تعديل تقرير الجرد",
  extension_requests_reports: "تقرير طلبات التمديد",

  // F
  filter_by_status: "الفلتره حسب الحاله",
  filter_by_request_status: "الفلتره حسب حالة الطلب",
  filter_by_request_type: "الفلتره حسب نوع الطلب",
  filter_by_report_status: "الفلتره حسب حاله التقرير",
  files: "الملفات",
  failed: "فشل",

  // G
  geolocation_not_supported_your_browser:
    "لا يدعم المستعرض الخاص بك تحديد الموقع الجغرافي",
  group_branches: "فروع المجموعه",

  // H

  // I
  images: "الصور",
  image_section_reports: "تقارير صور الاقسام",
  igf_type: "النوع",

  // J

  // K

  // L
  last_upload: "اخر رفع",

  // M
  mobile: "الجوال",

  max_length: "اقصى طول للنص {{max}}",
  min_length: "اقل طول للنص {{min}}",
  must_be_less_than: "يجب أن يكون النص أقل من {{max}}",
  must_be_more_than: "يجب أن يكون النص أكبر من {{min}}",
  meter: "متر/أمتار",
  more: "المزيد",

  // N
  no_data: "لا توجد بيانات",
  no: "لا",

  number_msg: "المدخل يجب ان يكون ارقام فقط",
  number_ar_msg: "المدخل لا يمكن ان يحتوي على الارقام العربية",
  name: "الاسم",
  name_en: "الاسم الانجليزي",
  name_ar: "الاسم العربي",

  no_brands_available: "لا توجد علامات تجارية ",
  no_cities_available: "لا توجد مدن",
  no_branches_available: "لا توجد فروع",
  no_items_available: "لا توجد منتجات",
  no_users_available: "لا يوجد مستخدمون",
  no_admins_available: "لا يوجد مشرفون",
  no_clients_available: "لا يوجد عملاء",
  no_reports_available: "لا توجد تقارير",
  no_types_available: "لاتوجد آنواع",
  no_groups_available: "لاتوجد مجموعات",
  no_sections_available: "لاتوجد آقسام",
  no_sections: "لاتوجد آقسام",
  next: "التالي",
  no_assigned_branches_today: "لا توجد فروع مسنده اليوم",
  no_categories_available: "لا توجد فئات متاحة",

  // O
  ok: "تم",

  // P
  pdf: "PDF",
  password: "كلمة المرور",
  problem_while_loading_permissions: "يبدوا أن هناك مشكلة في تحميل الصلاحيات",
  per_page: "الاجمالي لكل صفحة:",
  previous: "السابق",
  pending: "في الانتظار",
  products_details: "تفاصيل المنتجات",
  product_type: "نوع المنتج",

  // Q
  product_name: "اسم المنتج",
  // R
  role: "الدور",
  required: "هذا الحقل اجباري",
  request_type: "نوع الطلب",
  report_requests_reports: "تقرير طلبات التقارير",
  request_status: "حالة الطلب",
  rejected: "مرفوض",
  reject: "رفض",
  requested_skip_count: "عدد طلبات التخطي",
  rtv_type: "النوع",

  // S
  show: "عرض",
  supervisor: "مشرف",

  search: "بحث ...",
  submit: "ارسال",
  status: "الحالة",
  should_start_with: "النص يجب أن يبدأ بـ  {{start}}",
  select_all: "تحديد الكل",
  show_image: "عرض الصورة",
  submit_filters: "تطبيق الفلاتر",
  search_by_user_code_or_name: "البحث باسم او كود المستخدم",
  search_by_user_code_or_name_or_email:
    "كود المستخدم أو الاسم أو البريد الإلكتروني",
  search_by_report_number_visitingEtc:
    "كود المستخدم أو اسم المستخدم أو رقم التقرير أو رقم الفرع",
  search_by_report_number_etc:
    "كود المستخدم أو اسم المستخدم أو رقم التقرير أو رقم الفرع أو اسم المنتج",
  size: "الحجم",
  search_for_city_place: "ابحث عن مدينة، مكان",
  show_group_branches: "عرض فروع المجموعه",
  show_images: "عرض الصور",
  show_product_details: "عرض تفاصيل المنتج",
  scheduler_branches: "جدوله الفروع",
  select_range: "تحديد نطاق",
  scheduled: "مجدول",
  scheduled_branches: "جدوله الفروع",
  success: "نجاح",

  // T
  total: "الاجمالي",
  type_user_name: "اكتب اسم المستخدم",
  type_password: "اكتب كلمة المرور",
  total_count: "العدد الاجمالي:",
  total_displayed: "اجمالي المعروض:",
  the_user_not_have_assigned: "لم يتم اسناد فروع للمستخدم",
  total_amount: "السعر الاجمالي",

  // U
  user: "مستخدم",
  upload: "رفع",
  upload_file: "رفع ملف",

  // V

  // W

  // X

  // Y
  yes: "نعم",
  you_cant_remove_the_record: "لايمكنك حذف السجل، لانه لم يتم تسجيل الخروج",

  // Z
};

export default Common;
