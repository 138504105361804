import React from "react";
import PropTypes from "prop-types";
import { Flex, Spacer, Text } from "atoms";

const InlineInfo = ({ title, value, width, justifyBetween }) => {
  return (
    <Flex
      justifyBetween={justifyBetween}
      itemsCenter
      style={{ width: width || "100%" }}
    >
      <Text
        typography="caption11"
        weight="bold"
        color="var(--brand-gray-secondary)"
      >
        {title}
      </Text>
      <Spacer width={8} />
      <Text typography="caption12" color="var(--brand-gray-primary)">
        {value}
      </Text>
    </Flex>
  );
};

InlineInfo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  justifyBetween: PropTypes.bool,
};

export default InlineInfo;
