// eslint-disable-next-line no-undef
const { retryImport } = require("utils/retry");

// Public Pages
const Login = retryImport(() => import("containers/public/login"));
const ForgotPassword = retryImport(() =>
  import("containers/public/forgotPass")
);
const ResetPassword = retryImport(() => import("containers/public/resetPass"));
const PrivacyPolicy = retryImport(() => import("containers/public/policy"));

const publicRoutes = [
  {
    path: "/Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
];

export { publicRoutes };
