const Admins = {
  // *

  // A
  admin: "مدير",
  admins: "مدراء",
  are_you_sure_you_want_to_delete_this_admin:
    "هل أنت متأكد أنك تريد حذف هذا المدير <b>{{name}}</b>",

  // B

  // C
  create_admin: "انشاء مدير",

  // D
  delete_admin: "حذف مدير",

  // E

  // F

  // G

  // H

  // I

  // J

  // K

  // L

  // M

  // N
  new_admin: "مدير جديد",

  // O

  // P

  // Q

  // R

  // S
  sub_admin: "نائب مدير",

  // T

  // U
  update_admin: "تحديث المدير",

  // V

  // W

  // X

  // Y

  // Z
};

export default Admins;
