import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as WarningIcon } from "assets/icons/common/warning_Icon.svg";
import MuiButton from "../MuiButton";
import i18n from "translation/i18n";
import { Flex, Text } from "atoms/html";
import { MsmLoader } from "components/global/loaders";

const MuiConfirm = ({ disabled, trigger, loading, message, onConfirm }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (disabled) return;
    setOpen(!open);
  };

  const handleConfirm = () => {
    onConfirm?.();
    setOpen(false);
  };

  return (
    <>
      {trigger &&
        React.cloneElement(trigger, {
          onClick: handleClick,
        })}

      <Dialog open={open}>
        {loading && <MsmLoader fixed />}
        <Flex
          flexCol
          justifyCenter
          itemsCenter
          style={{ padding: 20, width: 400, gap: 20 }}
        >
          <WarningIcon />
          <Text typography="paragraph16">
            {message || i18n.t("are_you_sure_to_delete_item")}
          </Text>

          <Flex style={{ gap: 4, width: "100%" }} justifyEnd>
            <MuiButton
              label={i18n.t("cancel")}
              onClick={handleClick}
              fitContent
              variant="text"
            />
            <MuiButton
              label={i18n.t("yes")}
              themeColor="warning"
              onClick={handleConfirm}
              fitContent
            />
          </Flex>
        </Flex>
      </Dialog>
    </>
  );
};

MuiConfirm.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  trigger: PropTypes.node,
  onConfirm: PropTypes.func,
};

export default React.memo(MuiConfirm);
