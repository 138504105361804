import React from "react";
import PropTypes from "prop-types";
import { Popover } from "@mui/material";
import { Flex, Text } from "atoms/html";
import { isRtl } from "utils/uiHelpers";

import Styles from "./MuiPopover.module.css";

const MuiPopover = (props) => {
  const { trigger, options, children } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: handleClick,
      })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isRtl ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isRtl ? "right" : "left",
        }}
      >
        {children ? (
          children
        ) : (
          <Flex flexCol className={Styles.options}>
            {options?.map((option, i) => (
              <Flex
                key={i}
                className={option.isActive && Styles.activeItem}
                itemsCenter
                onClick={() => {
                  setAnchorEl(null);
                  option?.onClick();
                }}
              >
                <Text typography="paragraph14">{option.label}</Text>
              </Flex>
            ))}
          </Flex>
        )}
      </Popover>
    </>
  );
};

MuiPopover.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.node,
  options: PropTypes.arrayOf(Object),
};

export default MuiPopover;
