export const MODULES = {};

export const PAGES = {
  OVERVIEW: "overview",
  ADMINS: "admins",
  CLIENTS: "clients",
  USERS: "users",
  BRANCHES: "branches",
  BRANDS: "brands",
  ITEMS: "items",
  CITIES: "cities",
  REPORTS: "reports",
  INVENTORY_NOTIFICATION: "inventory_notifications",
  SETTINGS: "settings",
  USER_PROFILE: "user_profile",
  RTV_REPORTS: "rtv_reports",
};

export const PERMISSION_KEYS = {
  SHOW: "show",
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
};

export const USER_ROLES = {
  SUPER_ADMIN: "super_admin",
  SUB_ADMIN: "sub_admin",
  CLIENT: "client",
  CLIENT_ADMIN: "client_admin",
  SUPERVISOR: "supervisor",
  USER: "user",
};

export const PERMISSIONS = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
};

export const ACTIONS = {
  ADD: "add",
  UPDATE: "update",
  DELETE: "delete",
  INFO: "info",
};

export const ACTION_THEMES = {
  ADD: "success",
  UPDATE: "warning",
  DELETE: "warning",
  INFO: "info",
};

export const FILTER_NAMES = {
  SEARCH: "search",
  USERS: "users",
  CITIES: "cities",
  BRANCHES: "branches",
  BRANDS: "brands",
  ITEMS: "items",
  DATE: "date",
  RANGE_DATE: "range_date",
  MANAGE_TYPES: "manage_types",
  BRANCHES_GROUP: "branches_group",
  STATUS: "status",
  SECTIONS: "sections",
  REQUEST_TYPE: "request_type",
  REQUEST_STATUS: "request_status",
  REPORT_STATUS: "report_status",
};

export const SORTING = {
  ASC: "asc",
  DESC: "desc",
};
