import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./flex.module.css";

const Flex = ({
  children,
  justifyBetween,
  flexRow,
  flexRowReverse,
  flexCol,
  flexColReverse,
  flexNoWrap,
  flexWrapReverse,
  flexWrap,
  flexGrowEqual,
  justifyStart,
  justifyEnd,
  justifyCenter,
  justifyAround,
  justifyEvenly,
  justifyItemsStart,
  justifyItemsEnd,
  justifyItemsCenter,
  justifyItemsStretch,
  contentCenter,
  contentStart,
  contentEnd,
  contentBetween,
  contentAround,
  contentEvenly,
  itemsStart,
  itemsEnd,
  itemsCenter,
  itemsBaseline,
  itemsStretch,
  fullWidth,
  fullHeight,
  className,
  ...rest
}) => (
  <div
    className={clsx(
      styles.flex,
      // direction
      flexRow && styles.flexRow,
      flexRowReverse && styles.flexRowReverse,
      flexCol && styles.flexCol,
      flexColReverse && styles.flexColReverse,
      // wrap
      flexNoWrap && styles.flexNoWrap,
      flexWrapReverse && styles.flexWrapReverse,
      flexWrap && styles.flexWrap,
      // grow
      flexGrowEqual && styles.flexGrowEqual,
      // justify-content
      justifyStart && styles.justifyStart,
      justifyEnd && styles.justifyEnd,
      justifyCenter && styles.justifyCenter,
      justifyBetween && styles.justifyBetween,
      justifyAround && styles.justifyAround,
      justifyEvenly && styles.justifyEvenly,
      // justify-items
      justifyItemsStart && styles.justifyItemsStart,
      justifyItemsEnd && styles.justifyItemsEnd,
      justifyItemsCenter && styles.justifyItemsCenter,
      justifyItemsStretch && styles.justifyItemsStretch,
      // align-content
      contentCenter && styles.contentCenter,
      contentStart && styles.contentStart,
      contentEnd && styles.contentEnd,
      contentBetween && styles.contentBetween,
      contentAround && styles.contentAround,
      contentEvenly && styles.contentEvenly,
      // align-items
      itemsStart && styles.itemsStart,
      itemsEnd && styles.itemsEnd,
      itemsCenter && styles.itemsCenter,
      itemsBaseline && styles.itemsBaseline,
      itemsStretch && styles.itemsStretch,
      fullWidth && styles.fullWidth,
      fullHeight && styles.fullHeight,
      // other
      className
    )}
    {...rest}
  >
    {children}
  </div>
);

Flex.propTypes = {
  children: PropTypes.node,
  justifyBetween: PropTypes.bool,
  flexRow: PropTypes.bool,
  flexRowReverse: PropTypes.bool,
  flexCol: PropTypes.bool,
  flexColReverse: PropTypes.bool,
  flexNoWrap: PropTypes.bool,
  flexWrapReverse: PropTypes.bool,
  flexWrap: PropTypes.bool,
  flexGrowEqual: PropTypes.bool,
  justifyStart: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyAround: PropTypes.bool,
  justifyEvenly: PropTypes.bool,
  justifyItemsStart: PropTypes.bool,
  justifyItemsEnd: PropTypes.bool,
  justifyItemsCenter: PropTypes.bool,
  justifyItemsStretch: PropTypes.bool,
  contentCenter: PropTypes.bool,
  contentStart: PropTypes.bool,
  contentEnd: PropTypes.bool,
  contentBetween: PropTypes.bool,
  contentAround: PropTypes.bool,
  contentEvenly: PropTypes.bool,
  itemsStart: PropTypes.bool,
  itemsEnd: PropTypes.bool,
  itemsCenter: PropTypes.bool,
  itemsBaseline: PropTypes.bool,
  itemsStretch: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
};

export default Flex;
