import { createSlice } from "@reduxjs/toolkit";

const variant = {
  true: "success",
  false: "error",
};

const initialState = {
  message: "",
  open: false,
  variant: "success",
  left: null,
  top: null,
  right: null,
  bottom: null,
};

const toasterSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    setToasterInfo: (state, action) => {
      state.message =
        action.payload?.message || "error_while_loading_the_message";
      state.variant =
        action.payload?.variant || variant[action.payload?.success];
      state.open = action.payload?.open;
      state.left = action.payload?.left;
      state.top = action.payload?.top;
      state.right = action.payload?.right;
      state.bottom = action.payload?.bottom;
    },
    clearToasterInfo: (state) => {
      state.message = "";
      state.variant = "success";
      state.open = false;
      state.left = null;
      state.top = null;
      state.right = null;
      state.bottom = null;
    },
  },
});

export default toasterSlice.reducer;
export const { setToasterInfo, clearToasterInfo } = toasterSlice.actions;
