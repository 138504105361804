import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const MuiSelect = (props) => {
  const {
    style,
    status,
    error,
    helperText,
    variant,
    size,
    id,
    options,
    label,
    onChange,
    defaultValue,
    // isClearable,
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  // const [tempValue, setTempValue] = React.useState(defaultValue);
  const textStatuses = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ];
  const inputStatus = React.useMemo(
    () => (textStatuses.includes(status) ? status : ""),
    [status]
  );

  const handleOnChange = ({ target }) => {
    setSelectedValue(target.value);
    // setTempValue(target.value);
    onChange(target);
  };

  return (
    <FormControl sx={{ width: "100%", minWidth: "20vw" }} size="small">
      {label && <InputLabel id={`label-${id}`}>{label}</InputLabel>}
      <Select
        {...rest}
        {...props}
        id={id}
        labelId={`label-${id}`}
        style={{ width: "100%", ...style }}
        color={inputStatus || "secondary"}
        status={inputStatus}
        error={error}
        helperText={helperText}
        variant={variant}
        size={size}
        value={selectedValue}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        MenuProps={{
          style: {
            maxHeight: "30vh",
          },
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MuiSelect.defaultProps = {
  variant: "outlined",
  size: "medium",
  id: "combo-box",
};

MuiSelect.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.shape(),
  status: PropTypes.string,
  helperText: PropTypes.bool,
  error: PropTypes.bool,
  // isClearable: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape(),
  options: PropTypes.arrayOf(Object),
};

export default MuiSelect;

/*

<ClickAwayListener onClickAway={() => {  setSelectedValue(tempValue); }}>
			<Autocomplete
				{...rest}
				id={id}
				options={options}
				onChange={handleOnChange}
				value={selectedValue}
				defaultValue={defaultValue}
				color={inputStatus || "secondary"}
				status={inputStatus}
				error={error}
				helperText={helperText}
				variant={variant}
				size={size}
				style={{ width: "100%", ...style }}
				disableClearable={!isClearable}
				disablePortal
				ListboxProps={{
					style: {
						maxHeight: "30vh"
					},
				}}
				renderInput={(params) => <TextField
					{...params}
					type="text"
					autoComplete="off"
					label={label}
					value={selectedValue}
				/>}
			/>
		</ClickAwayListener>

*/
