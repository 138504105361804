import React from "react";
import PropTypes from "prop-types";
import { Flex, Text, Spacer } from "atoms";
import { ReactComponent as EmptyBox } from "assets/icons/common/empty-box.svg";

const EmptyData = (props) => {
  const { withIcon, icon, title, message, style, variant } = props;

  const color = {
    error: "var(--brand-error-primary)",
    warning: "var(--brand-warning-primary)",
    success: "var(--brand-success-primary)",
    info: "var(--brand-info-primary)",
  };

  return (
    <Flex flexCol itemsCenter style={{ ...style }} {...props}>
      {!!withIcon && (icon || <EmptyBox height={48} width={48} />)}
      <Spacer height={8} />
      {title && (
        <Text
          typography="title"
          color={color[variant] || "var(--brand-primary)"}
        >
          {title}
        </Text>
      )}
      <Spacer height={8} />
      {message && (
        <Text
          typography="paragraph16"
          color={color[variant] || "var(--brand-primary)"}
        >
          {message}
        </Text>
      )}
    </Flex>
  );
};

EmptyData.propTypes = {
  variant: PropTypes.string,
  withIcon: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  style: PropTypes.shape(),
};

export default EmptyData;
