import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
// export const NOTIFICATION = "notification";
export const SEND_NOTIFICATION = "send_notification";
export const DELETE_NOTIFICATION = "delete_notification";

export const sendNotification = createAsyncThunk(
  `${ADMIN}/${SEND_NOTIFICATION}`,
  (data = {}) => {
    const url = `/${API_VERSION_ONE}/admin/notifications`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteNotification = createAsyncThunk(
  `${ADMIN}/${DELETE_NOTIFICATION}`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/report_requests/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method);
  }
);
