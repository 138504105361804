import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoutes } from "routers/paths";

const PublicRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map(({ path, component: Component }, index) => (
        <Route key={index} path={path} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to="/login" replace />} to="/login" />
    </Routes>
  );
};

export default PublicRoutes;
