import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  loadSections,
  deleteSection,
  showSection,
  updateSection,
  createNewSection,
} from "./actions";

const initialState = {
  fetching: false,
  loading: false,
  sections: [],
  pagination: {},
  section: {},
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetSection: (state) => {
      state.section = {};
    },
  },
  extraReducers: (builder) => {
    // section list
    builder.addCase(loadSections.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadSections.fulfilled, (state, action) => {
      state.fetching = false;
      state.sections = action.payload.data?.data?.sections || [];
      state.pagination = action.payload.data?.data?.pagination || {};
    });
    builder.addCase(loadSections.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Create Section
    builder.addCase(createNewSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewSection.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createNewSection.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Section
    builder.addCase(updateSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSection.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateSection.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Show Section
    builder.addCase(showSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showSection.fulfilled, (state, action) => {
      state.loading = false;
      state.section = action.payload.data?.data?.section || {};
    });
    builder.addCase(showSection.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete BranchSection
    builder.addCase(deleteSection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSection.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteSection.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
  },
});

export default slice.reducer;

export const { resetSection } = slice.actions;
