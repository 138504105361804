import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  loadUsers,
  updateUser,
  deleteUser,
  createUser,
  showUser,
} from "./actions";

const initialState = {
  fetching: false,
  users: [],
  user: {},
  pagination: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    // Users list
    builder.addCase(loadUsers.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadUsers.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.users = action.payload.data?.data.users;
        state.pagination = action.payload.data?.data?.pagination || {};
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadUsers.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // user detail
    builder.addCase(showUser.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(showUser.fulfilled, (state, action) => {
      state.fetching = false;

      if (action.payload?.data?.success) {
        state.user = action.payload.data?.data?.user;
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(showUser.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
    // Create User
    builder.addCase(createUser.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // User Update
    builder.addCase(updateUser.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // User Delete
    builder.addCase(deleteUser.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.users = [];
      state.message = "";
    });
  },
});

export default slice.reducer;

export const { resetUser } = slice.actions;
