import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { MuiBox, Text, Spacer } from "atoms";
import styles from "./styles.module.css";

const GroupBox = ({ children, title, ...rest }) => (
  <MuiBox className={clsx(styles.groupboxContainer)} {...rest}>
    {title && (
      <Text typography="subtitle" className={styles.groupboxContainerTitle}>
        {title}
      </Text>
    )}
    <Spacer height={16} />
    {children}
  </MuiBox>
);

GroupBox.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default GroupBox;
