import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import i18n from "translation/i18n";
import { Toastr } from "components/global/toasterHandler/Toastr";
import Text from "../Text";

import Styles from "./FileInput.module.css";
import Flex from "../Flex";
import Spacer from "../Spacer";

const FileInput = (props) => {
  const {
    onDone,
    multiple,
    disabled,
    className,
    showUploadSection,
    dropzoneProps,
  } = props;

  const onDrop = React.useCallback((acceptedFiles) => {
    const fileList = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => Toastr.error("file_reading_was_aborted");
      reader.onerror = () => Toastr.error("file_reading_has_failed");
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileInfo = {
          file_file_name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          file_size: Math.round(file.size / 1000),
          file: reader.result,
        };
        fileList.push(fileInfo);

        if (fileList.length === acceptedFiles.length) {
          if (multiple) onDone(fileList);
          else onDone(fileList[0]);
        }
      };
    });
  }, []);

  const { getRootProps, acceptedFiles, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      ...dropzoneProps,
    });

  return (
    <Fragment>
      <div {...getRootProps()} className={className}>
        <input {...getInputProps()} disabled={disabled} />
        {isDragActive && !showUploadSection && (
          <p>{i18n.t("drop_the_files_here")}</p>
        )}
        {showUploadSection && (
          <Flex
            justifyCenter
            flexCol
            itemsCenter
            className={Styles.dropdownInput}
          >
            <Text typography="paragraph14">
              {i18n.t("drag_files_or_click_to_select")}
            </Text>
          </Flex>
        )}
      </div>

      {showUploadSection && acceptedFiles?.length > 0 && (
        <Flex flexCol>
          <Spacer height={24} />
          <Text typography="paragraph16">{i18n.t("files")}</Text>
          <Text
            typography="caption12"
            style={{ display: "list-item", marginInlineStart: 24 }}
          >
            {acceptedFiles[0].path}
          </Text>
        </Flex>
      )}
    </Fragment>
  );
};

FileInput.propTypes = {
  onDone: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  showUploadSection: PropTypes.bool,
  className: PropTypes.string,
  dropzoneProps: PropTypes.shape(),
};

export default FileInput;
