export const tags = {
  display64: "h1", // 64px
  display50: "h2", // 50px
  display36: "h3", // 36px
  headline: "h4", // 24px
  title: "h4", // 20px
  subtitle: "h5", // 18px
  paragraph16: "p", // 16px
  paragraph14: "p", // 14px
  caption12: "small", // 12px
  caption10: "small", // 10px
};

export const weightOptions = ["light", "medium", "bold"];
