import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, downloadAPI, handleAPI } from "utils/apiUtils";
import { downloadS3Image } from "utils/uiHelpers";

export const loadBulkImportList = createAsyncThunk(
  "get/bulk_list",
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/bulk_imports`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const uploadBulkFile = createAsyncThunk(
  "upload/bulk_file",
  ({ id, data }) => {
    const url = `/${API_VERSION_ONE}/admin/bulk_imports/${id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const downloadBulkSampleFile = createAsyncThunk(
  "download/sample_file",
  ({ id, sampleName }) => {
    const extension = ".xls";
    const url = `/${API_VERSION_ONE}/admin/bulk_imports/${id}/export_sample_file${extension}`;

    return downloadAPI(url, `${sampleName}${extension}`);
  }
);

export const downloadFileError = createAsyncThunk(
  "download/file_error",
  ({ id, sampleName }) => {
    const extension = ".xls";
    const url = `/${API_VERSION_ONE}/admin/bulk_imports/${id}/export_errors_file${extension}`;

    return downloadAPI(url, `${sampleName}${extension}`);
  }
);

export const downloadUploadedFile = createAsyncThunk(
  "download/downloaded_uploaded_file",
  ({ url, name }) => {
    return downloadS3Image(url, name);
  }
);
