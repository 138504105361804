import React from "react";
import PropTypes from "prop-types";
import { MuiTooltip } from "atoms";

const ToolTipHandler = ({ show, title, children, style }) => {
  return (
    <>
      {show ? (
        <MuiTooltip title={title} style={{ width: "100%" }}>
          <div style={{ cursor: "pointer", ...style }}>{children}</div>
        </MuiTooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

ToolTipHandler.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.shape(),
};

export default ToolTipHandler;
