import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";

export const loadTypes = createAsyncThunk("get/types", (params = {}) => {
  const url = `/${API_VERSION_ONE}/admin/igf_types`;
  const method = "GET";

  return handleAPI(url, params, method);
});

export const showType = createAsyncThunk("show/type", (id) => {
  const url = `/${API_VERSION_ONE}/admin/igf_types/${id}`;
  const method = "GET";

  return handleAPI(url, {}, method);
});

export const createNewType = createAsyncThunk("create/types", (params = {}) => {
  const url = `/${API_VERSION_ONE}/admin/igf_types`;
  const method = "POST";

  return handleAPI(url, params, method);
});

export const updateType = createAsyncThunk("update/types", (params = {}) => {
  const url = `/${API_VERSION_ONE}/admin/igf_types/${params.id}`;
  const method = "PUT";

  return handleAPI(url, {}, method, { igf_type: params.igf_type });
});

export const deleteType = createAsyncThunk(`${ADMIN}/DELETE_TYPE`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/igf_types/${id}`;

  return handleAPI(url, {}, "DELETE");
});
