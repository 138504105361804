import React from "react";
import { Helmet } from "react-helmet";
import i18n from "translation/i18n";
import { BrowserRouter } from "react-router-dom";
import RootRouter from "routers/RootRouter";
import { createTheme, ThemeProvider } from "@mui/material";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";

const App = () => {
  const language = localStorage.getItem("Locale");
  const dir = language === "ar" ? "rtl" : "ltr";
  const theme = createTheme({ direction: dir });

  const cache = createCache({
    key: `mui${dir}`,
    ...(dir === "rtl" && { stylisPlugins: [prefixer, rtlPlugin] }),
  });

  return (
    <>
      <Helmet>
        <html lang={language} dir={dir} />
        <title>{i18n.t("app_title")}</title>
        <body dir={dir} />
      </Helmet>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <RootRouter />
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default App;
