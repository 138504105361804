import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
const CLIENTS = "clients";
const SHOW_CLIENT = "show_client";
const VALIDATE_CLIENTS_STEPS = "validate_clients_steps";
const LIMITAATIONS = "limitations";
const CREATE_CLIENT = "create_client";
const UPDATE_CLIENT = "update_client";
const DELETE_CLIENT = "delete_client";

export const loadClients = createAsyncThunk(
  `${ADMIN}/${CLIENTS}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/clients`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const showClient = createAsyncThunk(`${ADMIN}/${SHOW_CLIENT}`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/clients/${id}`;
  const method = "GET";

  return handleAPI(url, {}, method);
});

export const validateClientSteps = createAsyncThunk(
  `${ADMIN}/${VALIDATE_CLIENTS_STEPS}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/clients/validate_steps`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const loadLimitations = createAsyncThunk(
  `${ADMIN}/${LIMITAATIONS}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/limitations`;
    const method = "GET";
    return handleAPI(url, params, method);
  }
);

export const createClient = createAsyncThunk(
  `${ADMIN}/${CREATE_CLIENT}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/clients`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateClient = createAsyncThunk(
  `${ADMIN}/${UPDATE_CLIENT}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/clients/${data.user?.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteClient = createAsyncThunk(
  `${ADMIN}/${DELETE_CLIENT}`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/clients/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method);
  }
);
