import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  createCity,
  deleteCity,
  loadCities,
  updateCity,
} from "./actions";

const initialState = {
  fetching: false,
  cities: [],
  pagination: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  extraReducers: (builder) => {
    // cities list
    builder.addCase(loadCities.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadCities.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.cities = action.payload.data?.data.cities;
        state.pagination = action.payload.data?.data?.pagination || {};
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadCities.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Create cities
    builder.addCase(createCity.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(createCity.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createCity.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update city
    builder.addCase(updateCity.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateCity.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateCity.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete city
    builder.addCase(deleteCity.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteCity.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteCity.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.cities = [];
      state.message = "";
    });
  },
});

export default slice.reducer;
