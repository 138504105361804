import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  AUTH,
  login,
  resetPassword,
  changePassword,
  logout,
  removeAuthUser,
  setAuthToken,
  setAuthUser,
} from "./actions";

const initialState = {
  fetching: false,
  auth: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: AUTH,
  initialState,
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        setAuthToken(action.payload.data?.data?.access_token);
        setAuthUser(action.payload.data?.data?.user);
        state.auth = action.payload.data?.data;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.fetching = false;
      state.auth = {};
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // reset password
    builder.addCase(resetPassword.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.users = action.payload.data?.data;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.fetching = false;
      state.users = [];
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // change password
    builder.addCase(changePassword.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.users = action.payload.data.data;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.fetching = false;
      state.users = [];
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // logout
    builder.addCase(logout.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.users = action.payload.data?.data;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
        Toastr.success(action.payload.data?.message);
        removeAuthUser();
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.fetching = false;
      state.users = [];
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.auth = {};
      state.message = "";
    });
  },
});

export default slice.reducer;
