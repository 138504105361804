import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Text } from "atoms";
import styles from "./linkText.module.css";

const LinkText = ({ label, link, target, style, ...props }) => {
  return (
    <Link to={link} target={target} style={{ textDecoration: "none" }}>
      <Text
        className={styles.linkButton}
        style={{ ...style }}
        typography="caption12"
        weight="bold"
        {...props}
      >
        {label}
      </Text>
    </Link>
  );
};

LinkText.defaultProps = {
  onClick: () => {},
  target: "_self",
};

LinkText.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
};

export default LinkText;
