import React from "react";
import PropTypes from "prop-types";

const Spacer = ({ width, height, minWidth, minHeight, style, ...rest }) => (
  <div {...rest} style={{ ...style, width, height, minWidth, minHeight }} />
);

Spacer.defaultProps = {
  width: 0,
  height: "100%",
};

Spacer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
};

export default Spacer;
