import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const CITIES = "cities";
export const CREATE_CITY = "create_city";
export const UPDATE_CITY = "update_city";
export const DELETE_CITY = "delete_city";

export const loadCities = createAsyncThunk(
  `${ADMIN}/${CITIES}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/cities`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const createCity = createAsyncThunk(
  `${ADMIN}/${CREATE_CITY}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/cities`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateCity = createAsyncThunk(
  `${ADMIN}/${UPDATE_CITY}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/cities/${data.city.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteCity = createAsyncThunk(`${ADMIN}/${DELETE_CITY}`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/cities/${id}`;
  const method = "DELETE";

  return handleAPI(url, {}, method);
});
