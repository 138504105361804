import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  createNewGroup,
  deleteBranchGroup,
  loadBranchGroups,
  showGroup,
  updateGroup,
} from "./actions";

const initialState = {
  fetching: false,
  loading: false,
  branch_groups: [],
  pagination: {},
  branch_group: {},
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetBranchGroup: (state) => {
      state.branch_group = {};
    },
  },
  extraReducers: (builder) => {
    // Group list
    builder.addCase(loadBranchGroups.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadBranchGroups.fulfilled, (state, action) => {
      state.fetching = false;
      state.branch_groups = action.payload.data?.data?.branch_groups || [];
      state.pagination = action.payload.data?.data?.pagination || {};
    });
    builder.addCase(loadBranchGroups.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Create Group
    builder.addCase(createNewGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewGroup.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createNewGroup.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Group
    builder.addCase(updateGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateGroup.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Show group
    builder.addCase(showGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.branch_group = action.payload.data?.data?.branch_group || {};
    });
    builder.addCase(showGroup.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete BranchGroup
    builder.addCase(deleteBranchGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBranchGroup.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteBranchGroup.rejected, (state, action) => {
      state.loading = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
  },
});

export default slice.reducer;

export const { resetBranchGroup } = slice.actions;
