import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import { statusColor } from "utils/uiHelpers";

const Cell = ({ label, status, children }) => {
  return (
    <td>
      {children ? (
        children
      ) : (
        <Text
          {...(status && { color: statusColor[status] })}
          typography="paragraph14"
          weight="bold"
        >
          {label}
        </Text>
      )}
    </td>
  );
};

Cell.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  children: PropTypes.node,
};

export default Cell;
