import React from "react";
import PropTypes from "prop-types";
import i18n from "translation/i18n";
import { Spacer, Flex } from "atoms";
import InlineInfo from "components/global/inlineInfo";

import { Pagination as PaginationItem } from "@mui/material";

const Pagination = (props) => {
  const { current_page, per_page, total_displayed, total_pages, handlePage } =
    props;

  return (
    <Flex justifyBetween itemsCenter style={{ width: "100%" }}>
      <Flex>
        <InlineInfo
          title={i18n.t("total_displayed")}
          value={total_displayed || 0}
          width="fit-content"
        />
        <Spacer width={16} />
        <InlineInfo
          title={i18n.t("per_page")}
          value={per_page || 0}
          width="fit-content"
        />
      </Flex>
      {!!total_pages && (
        <PaginationItem
          page={current_page}
          count={total_pages}
          variant="outlined"
          shape="rounded"
          onChange={(_, p) => handlePage(p)}
        />
      )}
    </Flex>
  );
};

Pagination.propTypes = {
  per_page: PropTypes.number,
  current_page: PropTypes.number,
  total_displayed: PropTypes.number,
  total_pages: PropTypes.number,
  handlePage: PropTypes.func,
};

export default Pagination;
