import i18n from "translation/i18n";

export const ACTIVATION_LIST = [
  { label: i18n.t("active"), value: "active" },
  { label: i18n.t("inactive"), value: "inactive" },
];

export const SIZE_TYPES_LIST = [
  { label: i18n.t("gram"), value: "gram" },
  { label: i18n.t("liter"), value: "liter" },
  { label: i18n.t("kilogram"), value: "kilogram" },
  { label: i18n.t("milliliter"), value: "milliliter" },
];

export const USER_ROLES = [
  { label: i18n.t("supervisor"), value: "supervisor" },
  { label: i18n.t("user"), value: "user" },
];

export const ADMIN_ROLES = [
  { label: i18n.t("supervisor"), value: "supervisor" },
  { label: i18n.t("sub_admin"), value: "sub_admin" },
];

export const HEADER_TEXT_PROPS = {
  typography: "paragraph14",
  color: "var(--brand-gray-secondary)",
  weight: "bold",
  style: { minWidth: "5vw" },
};

export const EMPTY_FILTER_PARAMS = {
  cities: [],
  branches: [],
  brands: [],
  items: [],
  users: [],
};
