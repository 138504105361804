import React from "react";
import { Flex, Spacer } from "atoms";
import InlineInfo from "components/global/inlineInfo";
import { Pagination, PaginationItem } from "@mui/material";
import i18n from "translation/i18n";
import styles from "./ApiPagination.module.css";

function ApiPagination(pagination, handlePage) {
  const { per_page, total_pages, count, current_page } = pagination;

  return (
    <Flex justifyBetween itemsCenter style={{ width: "100%" }}>
      <Flex className={styles.pagination_totals_section}>
        <InlineInfo
          title={i18n.t("total_displayed")}
          value={count || 0}
          width="fit-content"
        />
        {/* <Spacer width={16} />
				<InlineInfo title={i18n.t("total_count")} value={total_enteries || 0} width="fit-content" /> */}
        <Spacer width={16} />
        <InlineInfo
          title={i18n.t("per_page")}
          value={per_page || 0}
          width="fit-content"
        />
      </Flex>
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={current_page}
        count={total_pages}
        renderItem={(props2) => (
          <PaginationItem size="small" {...props2} disableRipple />
        )}
        onChange={(_, value) => handlePage(value)}
        style={{ minWidth: "fit-content" }}
        size="small"
      />
    </Flex>
  );
}

export default ApiPagination;
