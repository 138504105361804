import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Modal } from "@mui/material";

const MuiModal = ({ children, handleOnClose, open, ...rest }) => {
  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <Modal open={open} onClose={handleOnClose} {...rest}>
            {children}
          </Modal>,
          document.body
        )}
    </>
  );
};
MuiModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  handleOnClose: PropTypes.func,
};

export default MuiModal;
