import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { tags, weightOptions } from "./helper";
import styles from "./styles.module.css";

const textCases = {
  uppercase: "uppercase",
  lowercase: "lowercase",
  capitalize: "capitalize",
};

const Text = (props) => {
  const {
    children,
    typography,
    className,
    style,
    textCase,
    weight,
    center,
    color,
    cursor,
    width,
    minWidth,
    as,
    ...rest
  } = props;

  const typeface = tags[typography] || as;
  const Tag = as || typeface;
  const thick = React.useMemo(
    () => (weightOptions.includes(weight) ? weight : "medium"),
    [weight]
  );

  return (
    <Tag
      {...rest}
      className={clsx(styles[typography], `weight_${thick}`, className)}
      style={{
        textTransform: textCases[textCase] || "unset",
        textAlign: center ? "center" : "auto",
        color,
        cursor,
        width,
        minWidth,
        ...style,
      }}
    >
      {children}
    </Tag>
  );
};

Text.defaultProps = {
  typography: "headline",
  as: "p",
};

Text.propTypes = {
  style: PropTypes.shape(),
  as: PropTypes.node,
  children: PropTypes.node,
  typography: PropTypes.string,
  className: PropTypes.string,
  weight: PropTypes.string,
  textCase: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.bool,
};

export default Text;
