/* eslint-disable no-undef */
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getAuthUser } from "redux/integration/auth/actions";
import {
  isAuthorizedComponent,
  isSubAdminUser,
  isSuperAdminUser,
  isSupervisorUser,
  isSupervisorMindShare,
} from "utils/uiHelpers";
import { PAGES, PERMISSION_KEYS } from "models";
const { retryImport } = require("utils/retry");

// Authorized Pages
// Auth Pages
const ResetPass = retryImport(() => import("containers/public/resetPass"));
const PrivacyPolicy = retryImport(() => import("containers/public/policy"));

// Clients Nav
const Clients = retryImport(() => import("containers/authorized/clients/list"));
const ClientDetails = retryImport(() =>
  import("containers/authorized/clients/details")
);
const ClientBranches = retryImport(() =>
  import("components/authorized/clients/details/branches")
);
const ClientBrands = retryImport(() =>
  import("components/authorized/clients/details/brands")
);
const ClientItems = retryImport(() =>
  import("components/authorized/clients/details/items")
);
const ClientCities = retryImport(() =>
  import("components/authorized/clients/details/cities")
);
// const ClientPermissions = retryImport(() => import("components/authorized/clients/details/permissions"));
const UserPermissions = retryImport(() =>
  import("containers/authorized/clients/permissions")
);
const Notification = retryImport(() =>
  import("components/authorized/clients/details/notification")
);
const ClientUsers = retryImport(() =>
  import("components/authorized/clients/details/users")
);
const ClientDashboard = retryImport(() =>
  import("components/authorized/clients/details/dashboard")
);
const ManageTypes = retryImport(() =>
  import("components/authorized/clients/details/manageTypes")
);
const BranchGroup = retryImport(() =>
  import("components/authorized/clients/details/branchGroup")
);
const Sections = retryImport(() =>
  import("components/authorized/clients/details/sections")
);
const SchedulerBranches = retryImport(() =>
  import("components/authorized/clients/details/schedulerBranches")
);
const BulkImport = retryImport(() =>
  import("components/authorized/clients/details/bulkImport")
);

// Clients Reports
const ClientReports = retryImport(() =>
  import("containers/authorized/clients/reports")
);
const DialyReports = retryImport(() =>
  import("components/authorized/clients/reports/daily")
);
const NotificationReports = retryImport(() =>
  import("components/authorized/clients/reports/notifications")
);
const ExtensionRequestsReports = retryImport(() =>
  import("components/authorized/clients/reports/ExtensionRequests")
);
const IGFReports = retryImport(() =>
  import("components/authorized/clients/reports/igf")
);
const IGFReport = retryImport(() =>
  import("components/authorized/clients/reports/igf/report")
);
const IGFBranchReports = retryImport(() =>
  import("components/authorized/clients/reports/igf/branch")
);
const IGFItemReports = retryImport(() =>
  import("components/authorized/clients/reports/igf/item")
);

const RTVReports = retryImport(() =>
  import("components/authorized/clients/reports/rtv")
);
const RTVReport = retryImport(() =>
  import("components/authorized/clients/reports/rtv/report")
);
const RTVBranchReports = retryImport(() =>
  import("components/authorized/clients/reports/rtv/branch")
);
const RTVItemReports = retryImport(() =>
  import("components/authorized/clients/reports/rtv/item")
);

const SDRReports = retryImport(() =>
  import("components/authorized/clients/reports/sdr")
);
const SDRReport = retryImport(() =>
  import("components/authorized/clients/reports/sdr/report")
);
const SDRBranchReports = retryImport(() =>
  import("components/authorized/clients/reports/sdr/branch")
);
const SDRItemReports = retryImport(() =>
  import("components/authorized/clients/reports/sdr/item")
);

const InventoryReports = retryImport(() =>
  import("components/authorized/clients/reports/inventory")
);
const InventoryReport = retryImport(() =>
  import("components/authorized/clients/reports/inventory/report")
);
const InventoryBranchReports = retryImport(() =>
  import("components/authorized/clients/reports/inventory/branch")
);
const InventoryItemReports = retryImport(() =>
  import("components/authorized/clients/reports/inventory/item")
);
const ImageSectionsReports = retryImport(() =>
  import("components/authorized/clients/reports/imageSections")
);

const Supervisor = () => {
  const isFirstLogin = getAuthUser()?.is_change_password_required;
  const user = getAuthUser();
  const client_id = user?.client?.id;

  const isAuthorizedForPermission =
    isSuperAdminUser() || isSubAdminUser() || isSupervisorUser();
  const showAdminSection =
    isSuperAdminUser() || isSubAdminUser() || isSupervisorMindShare();

  const toUrl = client_id ? `/clients/${client_id}/dashboard` : "/clients";
  const navigatePath = isFirstLogin ? "/reset-password" : toUrl;

  return (
    <Routes>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/reset-password"
        element={<ResetPass withBack={!isFirstLogin} />}
      />
      {!client_id &&
        isAuthorizedComponent(PAGES.CLIENTS, PERMISSION_KEYS.SHOW) && (
          <Route path="/clients" element={<Clients />} />
        )}

      <Route path="/clients/:id" element={<ClientDetails />}>
        <Route path="dashboard" element={<ClientDashboard />} />
        {isAuthorizedComponent(PAGES.USERS, PERMISSION_KEYS.SHOW) && (
          <Route path="users" element={<ClientUsers />} />
        )}
        {isAuthorizedComponent(PAGES.BRANDS, PERMISSION_KEYS.SHOW) && (
          <Route path="brands" element={<ClientBrands />} />
        )}
        {isAuthorizedComponent(PAGES.BRANCHES, PERMISSION_KEYS.SHOW) && (
          <Route path="branches" element={<ClientBranches />} />
        )}
        {isAuthorizedComponent(PAGES.ITEMS, PERMISSION_KEYS.SHOW) && (
          <Route path="items" element={<ClientItems />} />
        )}
        {isAuthorizedComponent(PAGES.CITIES, PERMISSION_KEYS.SHOW) && (
          <Route path="cities" element={<ClientCities />} />
        )}
        {isAuthorizedComponent(PAGES.CITIES, PERMISSION_KEYS.SHOW) && (
          <Route path="notification" element={<Notification />} />
        )}
        <Route
          path="/clients/:id"
          element={<Navigate to="dashboard" replace />}
          to="dashboard"
        />
        {showAdminSection && (
          <Route path="manage_types" element={<ManageTypes />} />
        )}
        {showAdminSection && (
          <Route path="branch_group" element={<BranchGroup />} />
        )}
        {isSuperAdminUser() && (
          <Route path="bulk_import" element={<BulkImport />} />
        )}

        {showAdminSection && <Route path="sections" element={<Sections />} />}
        {showAdminSection && (
          <Route path="scheduler" element={<SchedulerBranches />} />
        )}
      </Route>

      {isAuthorizedForPermission && (
        <Route
          path="/clients/:id/users/:user_id/permissions"
          element={<UserPermissions />}
        />
      )}
      {isAuthorizedForPermission && (
        <Route
          path="/clients/:id/users/:user_id/*"
          element={<Navigate to="permissions" replace />}
          to="permissions"
        />
      )}

      {/* :::::::: CLIENT REPORTS :::::::: */}
      {isAuthorizedComponent(PAGES.REPORTS, PERMISSION_KEYS.SHOW) && (
        <Route
          path="/clients/:id/dashboard/reports"
          element={<ClientReports />}
        >
          <Route path="image_section" element={<ImageSectionsReports />} />
          <Route path="daily" element={<DialyReports />} />
          <Route path="report_requests" element={<NotificationReports />} />
          <Route
            path="extension_requests"
            element={<ExtensionRequestsReports />}
          />
          <Route
            path="igf"
            element={<Navigate to="igf/report" replace />}
            to="igf/report"
          />
          <Route path="igf" element={<IGFReports />}>
            <Route path="report" element={<IGFReport />} />
            <Route path="branch" element={<IGFBranchReports />} />
            <Route path="item" element={<IGFItemReports />} />
            <Route
              path="*"
              element={<Navigate to="report" replace />}
              to="report"
            />
          </Route>

          {isAuthorizedComponent(PAGES.RTV_REPORTS, PERMISSION_KEYS.SHOW) && (
            <>
              <Route
                path="rtv"
                element={<Navigate to="rtv/report" replace />}
                to="rtv/report"
              />
              <Route path="rtv" element={<RTVReports />}>
                <Route path="report" element={<RTVReport />} />
                <Route path="branch" element={<RTVBranchReports />} />
                <Route path="item" element={<RTVItemReports />} />
                <Route
                  path="*"
                  element={<Navigate to="report" replace />}
                  to="report"
                />
              </Route>
            </>
          )}

          <Route
            path="sdr"
            element={<Navigate to="igf/report" replace />}
            to="igf/report"
          />
          <Route path="sdr" element={<SDRReports />}>
            <Route path="report" element={<SDRReport />} />
            <Route path="branch" element={<SDRBranchReports />} />
            <Route path="item" element={<SDRItemReports />} />
            <Route
              path="*"
              element={<Navigate to="report" replace />}
              to="report"
            />
          </Route>

          <Route
            path="inventory"
            element={<Navigate to="inventory/report" replace />}
            to="inventory/report"
          />
          <Route path="inventory" element={<InventoryReports />}>
            <Route path="report" element={<InventoryReport />} />
            <Route path="branch" element={<InventoryBranchReports />} />
            <Route path="item" element={<InventoryItemReports />} />
            <Route
              path="*"
              element={<Navigate to="report" replace />}
              to="report"
            />
          </Route>

          <Route
            path="/clients/:id/dashboard/reports"
            element={<Navigate to="./../" replace />}
            to="./../"
          />
          <Route
            path="/clients/:id/dashboard/reports/*"
            element={<Navigate to="./../" replace />}
            to="./../"
          />
        </Route>
      )}
      {/* :::::::: ------- END OF CLIENT REPORTS ------- :::::::: */}

      <Route
        path="*"
        element={<Navigate to={navigatePath} replace />}
        to={navigatePath}
      />
    </Routes>
  );
};

export default Supervisor;
