import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { isEmpty } from "lodash";
import i18n from "translation/i18n";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Pagination, PaginationItem } from "@mui/material";
import EmptyData from "components/global/emptyData";
import InlineInfo from "components/global/inlineInfo";
import { MuiBox, MuiDivider, Flex, Spacer } from "atoms";
import styles from "./styles.module.css";

function CustomPagination(listCount, responsePagination) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { pageSize } = responsePagination; // total_enteries

  return (
    <Flex justifyBetween itemsCenter style={{ width: "100%" }}>
      <Flex className={styles.pagination_totals_section}>
        <InlineInfo
          title={i18n.t("total_displayed")}
          value={listCount || 0}
          width="fit-content"
        />
        {/* <Spacer width={16} />
				<InlineInfo title={i18n.t("total_count")} value={total_enteries || 0} width="fit-content" /> */}
        <Spacer width={16} />
        <InlineInfo
          title={i18n.t("per_page")}
          value={pageSize || 0}
          width="fit-content"
        />
      </Flex>
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        renderItem={(props2) => (
          <PaginationItem size="small" {...props2} disableRipple />
        )}
        onChange={(_, value) => apiRef.current.setPage(value - 1)}
        style={{ minWidth: "fit-content" }}
        size="small"
      />
    </Flex>
  );
}

function CustomNoRows(noRowMessage) {
  return (
    <EmptyData
      title={noRowMessage || i18n.t("no_rows")}
      style={{ paddingTop: "10%" }}
    />
  );
}

const MuiTable = ({
  className,
  columns,
  rows,
  boxHeight,
  checkboxSelection = false,
  withDivider = true,
  responsePagination,
  pagination = true,
  components,
  noRowMessage,
  withNumbering = true,
  rowHeight = 50,
  ...rest
}) => {
  const [pageSize, setPageSize] = React.useState(25);
  const size = pageSize > 100 ? 100 : pageSize;

  React.useEffect(() => {
    if (!isEmpty(responsePagination)) {
      const { per_page } = responsePagination;
      setPageSize(per_page);
    }
  }, [responsePagination]);

  const listColumns = columns.map((column) => ({
    ...column,
    headerClassName: styles.headerClassName,
  }));
  const countedList = rows.map((row, ind) => ({
    counting_number: ind + 1,
    ...row,
  }));
  const countedCol = [
    {
      field: "counting_number",
      headerName: "#",
      flex: 1,
      maxWidth: 100,
      minWidth: 50,
    },
  ];
  const handledColumns = withNumbering
    ? countedCol.concat(listColumns)
    : listColumns;
  const handledlist = withNumbering ? countedList : rows;

  return (
    <MuiBox height={boxHeight || "calc(100vh - 15rem)"} width="100%">
      {withDivider && <MuiDivider color="var(--brand-border-secondary)" />}
      <DataGrid
        className={clsx(styles.dataTable, className)}
        classes={{ width: "100%", ...styles }}
        columns={handledColumns}
        rows={handledlist}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        rowHeight={rowHeight}
        checkboxSelection={checkboxSelection}
        pageSize={size}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        rowBuffer={size}
        pagination={pagination}
        components={{
          Pagination:
            pagination &&
            (() =>
              CustomPagination(handledlist.length, {
                ...responsePagination,
                pageSize: size,
              })),
          NoRowsOverlay: () => CustomNoRows(noRowMessage),
          ...components,
        }}
        {...rest}
      />
    </MuiBox>
  );
};

MuiTable.propTypes = {
  className: PropTypes.string,
  noRowMessage: PropTypes.string,
  withDivider: PropTypes.bool,
  pagination: PropTypes.bool,
  withNumbering: PropTypes.bool,
  columns: PropTypes.arrayOf(Object),
  rows: PropTypes.arrayOf(Object),
  checkboxSelection: PropTypes.bool,
  responsePagination: PropTypes.shape(),
  components: PropTypes.shape(),
  boxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MuiTable;
