import { combineReducers } from "redux";

import filters from "./integration/app/filtersSlice";
import toaster from "./integration/app/toasterSlice";
import stateSlice from "./integration/app/stateSlice";
import auth from "./integration/auth/slice";

import clients from "./integration/clients/slice";
import branches from "./integration/clients/branches/slice";
import brands from "./integration/clients/brands/slice";
import items from "./integration/clients/items/slice";
import permissions from "./integration/clients/permissions/slice";
import users from "./integration/clients/users/slice";
import cities from "./integration/clients/cities/slice";
import dashboard from "./integration/clients/dashboard/slice";
import notification from "./integration/clients/notification/slice";
import reports from "./integration/clients/reports/slice";
import admins from "./integration/clients/admins/slice";
import manageTypes from "./integration/clients/manageTypes/slice";
import branchGroup from "./integration/clients/branchGroup/slice";
import sections from "./integration/clients/sections/slice";
import exportList from "./integration/clients/exportList/slice";
import scheduleBranches from "./integration/clients/scheduleBranches/slice";
import bulkImport from "./integration/clients/bulkImport/slice";

const rootSlices = combineReducers({
  filters,
  toaster,
  auth,
  stateSlice,

  clients,
  admins,
  branches,
  brands,
  items,
  permissions,
  users,
  cities,
  dashboard,
  notification,
  reports,
  manageTypes,
  branchGroup,
  sections,
  exportList,
  scheduleBranches,
  bulkImport,
});

export default rootSlices;
