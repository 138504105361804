import React from "react";
import { Avatar } from "@mui/material";
import styles from "./styles.module.css";

const MuiAvatar = (props) => {
  return (
    <Avatar
      {...props}
      className={styles.avatarStyle}
      style={{ border: 0, objectFit: "cover" }}
    />
  );
};

export default MuiAvatar;
