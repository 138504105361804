import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const loadSchedulerBranches = createAsyncThunk(
  `ADMIN/SCHEDULER_BRANCHES`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/clients/schedule_branches`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const createSchedulerBranch = createAsyncThunk(
  `ADMIN/CREATE_SCHEDULER_BRANCHES`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/schedule_branches`;
    const method = "POST";

    return handleAPI(url, params, method);
  }
);

export const editSchedulerBranch = createAsyncThunk(
  `ADMIN/EDIT_SCHEDULER_BRANCHES`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/schedule_branches`;
    const method = "PUT";

    return handleAPI(url, params, method);
  }
);
