const Common = {
  // *
  en: "EN",
  ar: "ع",

  // A
  app_title: "Mind-Share Management System",
  app_title_shortcut: "MSM System",
  actions: "Actions",
  allow_to_clock_offsite: "Allow to check in/out from geofencing",
  all: "All",
  are_you_sure: "Are you sure?",
  are_you_sure_to_delete_item: "Are you sure to delete this item?",
  action_by: "Action by",
  approved: "Approved",
  approve: "Approve",
  action_at: "Action at",

  // B
  bulk_upload: "Bulk upload",
  branch_number: "Branch number",
  by: "By",
  bulk_import: "Bulk Import",

  // C
  close: "Close",
  cancel: "Cancel",
  comming_soon: "Comming soon",
  check_your_connection_please:
    "There is something wrong with your internet connection, or your server is not connected, please check with your admin!",
  clear_filters: "Clear filters",
  creator_name: "Creator name",
  created_at: "Created at",
  creator_name_or_action_by_name: "Creator name or action by name",
  completed: "Completed",
  comment: "Comment",

  // D
  details: "Details",
  delete: "Delete",
  download_image: "Download Image",
  delete_image: "Delete Image",
  download_all_images: "Download all images",
  download_uploaded_file: "Download Uploaded File",
  download_sample: "Download Sample",
  download_errors: "Download Errors",
  drag_files_or_click_to_select: "Drag files or click to select",

  // E
  edit: "Edit",
  excel: "Excel",
  export: "Export",
  expiry_date: "Expiry date",

  email_msg: "Text should be in an email format",
  error: "Error",
  error_while_loading_the_reports:
    "There is an error while loading the reports",
  edit_igf_report: "Edit IGF report",
  edit_sdr_report: "Edit SDR report",
  edit_rtv_report: "Edit RTV report",
  edit_inventory_report: "Edit Inventory report",
  extension_requests_reports: "Extension requests reports",

  // F
  filter_by_status: "Filter by status",
  filter_by_request_type: "Filter by request type",
  filter_by_request_status: "Filter by request status",
  filter_by_report_status: "Filter by report status",
  files: "Files",
  failed: "Failed",
  // G
  geolocation_not_supported_your_browser:
    "Geolocation is not supported by your browser",
  group_branches: "Group branches",
  // H

  // I
  images: "Images",
  image_section_reports: "Image section reports",
  igf_type: "IGF type",

  // J

  // K

  // L
  last_upload: "Last Upload",

  // M
  mobile: "Mobile",

  max_length: "Maximum length is {{max}}",
  min_length: "Minimum length is {{min}}",
  must_be_less_than: "Text must be less than {{max}}",
  must_be_more_than: "Text must be more than {{min}}",
  meter: "Meter/Meters",
  more: "More",

  // N
  no: "No",
  no_data: "No data",
  number_msg: "Text must be a number only",
  number_ar_msg: "Text can not be in Arabic language",
  name: "Name",
  name_en: "Name en",
  name_ar: "Name ar",

  no_brands_available: "No brands available",
  no_cities_available: "No cities available",
  no_branches_available: "No branches available",
  no_items_available: "No items available",
  no_users_available: "No users available",
  no_admins_available: "No admins available",
  no_clients_available: "No clients available",
  no_reports_available: "No reports available",
  no_types_available: "No types available",
  no_groups_available: "No Groups available",
  no_sections_available: "No Sections available",
  no_sections: "No Sections",
  next: "Next",
  no_assigned_branches_today: "No assigned branches today",
  no_categories_available: "No Categories available",
  // O
  ok: "Ok",

  // P
  pdf: "PDF",
  problem_while_loading_permissions:
    "There is a problem while loading the permissions",

  password: "Password",
  per_page: "Per page:",
  previous: "Previous",
  pending: "Pending",
  products_details: "Products details",
  product_type: "Product type",

  // Q
  product_name: "Product Name",
  // R
  role: "Role",
  required: "This field is required",
  request_type: "Request type",
  report_requests_reports: "Report requests reports",
  request_status: "Request Status",
  rejected: "Rejected",
  reject: "Reject",
  requested_skip_count: "Requested skip count",
  rtv_type: "RTV type",

  // S
  show: "Show",
  supervisor: "Supervisor",
  search: "Search ...",
  submit: "Submit",
  status: "Status",
  should_start_with: "Text should start with {{start}}",
  select_all: "Select all",
  show_image: "Show image",
  submit_filters: "Submit filters",
  search_by_user_code_or_name: "Search by user code or name",
  search_by_user_code_or_name_or_email: "user code, name or email",
  search_by_report_number_visitingEtc:
    "user code, name, Report number or branch number",
  search_by_report_number_etc:
    "user code, name, Report number, branch number or product name",
  size: "Size",
  search_for_city_place: "Search for City, Place",
  show_group_branches: "Show group branches",
  show_images: "Show images",
  show_product_details: "Show product details",
  scheduler_branches: "Scheduler branches",
  select_range: "Select Range",
  scheduled: "Scheduled",
  scheduled_branches: "Scheduled branches",
  success: "Success",

  // T
  total: "Total",
  type_user_name: "Type user name",
  type_password: "Type password",
  total_count: "Total count:",
  total_displayed: "Total displayed:",
  the_user_not_have_assigned: "The user doesn't have assigned branches yet",
  total_amount: "Total amount",

  // U
  user: "User",
  upload: "Upload",
  upload_file: "Upload file",

  // V

  // W

  // X

  // Y
  yes: "Yes",
  you_cant_remove_the_record:
    "You can't remove the record, because it doesn't have a checkout",

  // Z
};

export default Common;
