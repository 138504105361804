// Import translations
import Auth from "./modules/en/auth";
import Common from "./modules/en/common";
import Clients from "./modules/en/clients";
import Admins from "./modules/en/admins";

const EN = {
  ...Auth,
  ...Common,
  ...Clients,
  ...Admins,
};

export default EN;
