import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const BRANCHES = "branches";
export const CREATE_BRANCH = "create_branch";
export const UPDATE_BRANCH = "update_branch";
export const DELETE_BRANCH = "delete_branch";

export const loadBranches = createAsyncThunk(
  `${ADMIN}/${BRANCHES}`,
  (params = {}) => {
    const url = `/${API_VERSION_ONE}/admin/branches`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const showBranch = createAsyncThunk(`${ADMIN}/SHOW_BRANCH`, (id) => {
  const url = `/${API_VERSION_ONE}/admin/branches/${id}`;
  const method = "GET";

  return handleAPI(url, {}, method);
});

export const createBranch = createAsyncThunk(
  `${ADMIN}/${CREATE_BRANCH}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/branches`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateBranch = createAsyncThunk(
  `${ADMIN}/${UPDATE_BRANCH}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/branches/${data.branch.id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteBranch = createAsyncThunk(
  `${ADMIN}/${DELETE_BRANCH}`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/branches/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method);
  }
);
