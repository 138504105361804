import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { Text } from "atoms";
import styles from "./styles.module.css";
import { MuiFormControlLabel } from "..";

const MuiCheckbox = (props) => {
  const { label, variant = "primary" } = props;

  const variantStyles = {
    primary: styles.primary_checkbox,
    secondary: styles.secondary_checkbox,
    info: styles.info_checkbox,
    success: styles.success_checkbox,
    warning: styles.warning_checkbox,
    error: styles.error_checkbox,
  };

  return (
    <MuiFormControlLabel
      control={
        <Checkbox
          {...props}
          className={clsx(styles.checkbox_Style, variantStyles[variant])}
        />
      }
      label={<Text typography="paragraph16">{label}</Text>}
      className={clsx(styles.checkbox_Style, variantStyles[variant])}
    />
  );
};

MuiCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
};

export default MuiCheckbox;
