const Admins = {
  // *

  // A
  admin: "Admin",
  admins: "Admins",
  are_you_sure_you_want_to_delete_this_admin:
    "Are you sure you want to delete this admin <b>{{name}}</b>",

  // B

  // C
  create_admin: "Create admin",

  // D
  delete_admin: "delete_admin",

  // E

  // F

  // G

  // H

  // I

  // J

  // K

  // L

  // M

  // N
  new_admin: "New admin",

  // O

  // P

  // Q

  // R

  // S
  sub_admin: "Sub-admin",

  // T

  // U
  update_admin: "Update admin",

  // V

  // W

  // X

  // Y

  // Z
};

export default Admins;
