import React from "react";
import { MuiBox, MuiLoader } from "atoms";
import styles from "styles/components/global.module.css";
import { Flex } from "atoms";

const ButtonLoader = () => {
  return (
    <Flex justifyCenter>
      <MuiBox className={styles.button_loader}>
        <MuiLoader style={{ color: "var(--brand-primary)" }} />
      </MuiBox>
    </Flex>
  );
};

export default ButtonLoader;
