import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  loadActionAuthorities,
  updateActionAuthorities,
} from "./actions";

const initialState = {
  fetching: false,
  action_authorities: [],
  user: {},
  pagination: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  extraReducers: (builder) => {
    // Action Authorities list
    builder.addCase(loadActionAuthorities.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(loadActionAuthorities.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.action_authorities =
          action.payload.data?.data?.action_authorities;
        state.user = action.payload.data?.data?.user;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadActionAuthorities.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Action Authorities
    builder.addCase(updateActionAuthorities.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateActionAuthorities.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateActionAuthorities.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.action_authorities = [];
      state.pagination = [];
      state.user = {};
      state.message = "";
    });
  },
});

export default slice.reducer;
