import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_VERSION_ONE, downloadAPI, handleAPI } from "utils/apiUtils";

export const ADMIN = "admin";
export const NOTIFICATION_RECORDS = "notification_records";
export const ATTENDANCE_RECORDS = "attendance_records";
export const EXPORT_RECORDS = "export_records";
export const IGF = "igf";
export const IGF_ITEMS = "igf_items";
export const IGF_BRANCHES = "igf_branches";
export const SDR = "sdr";
export const SDR_ITEMS = "sdr_items";
export const SDR_BRANCHES = "sdr_branches";
export const INVENTORY = "inventory";
export const INVENTORY_ITEMS = "inventory_items";
export const INVENTORY_BRANCHES = "inventory_branches";
export const REQUEST_REPORT = "request_report";

const exportingURLs = {
  notification: "notification_records",
  attendance: "attendance_records",
  igf: "igf_reports",
  igf_items: "group_by_product",
  igf_branches: "group_by_branch",
  rtv: "rtv_reports",
  rtv_items: "group_by_product",
  rtv_branches: "group_by_branch",
  sdr: "sdr_reports",
  sdr_items: "group_by_product",
  sdr_branches: "group_by_branch",
  inventory: "inventory_reports",
  inventory_items: "group_by_product",
  inventory_branches: "group_by_branch",
  section_images: "section_images",
};

const reportSection = {
  igf_branches: "igf_reports",
  igf_items: "igf_reports",
  rtv_branches: "rtv_reports",
  rtv_items: "rtv_reports",
  sdr_branches: "sdr_reports",
  sdr_items: "sdr_reports",
  inventory_branches: "inventory_reports",
  inventory_items: "inventory_reports",
};

export const loadExtensionsRequests = createAsyncThunk(
  `${ADMIN}/EXTENSION_REQUESTS`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/extension_requests`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const approveRejectExtensionRequest = createAsyncThunk(
  `${ADMIN}/APPROVE_REJECT_EXTENSION_REQUESTS`,
  ({ id, params }) => {
    const url = `/${API_VERSION_ONE}/admin/extension_requests/${id}`;
    const method = "PUT";

    return handleAPI(url, { extension_request: params }, method);
  }
);

export const loadNotificationRecords = createAsyncThunk(
  `${ADMIN}/${NOTIFICATION_RECORDS}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/report_requests`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const loadAttendanceRecords = createAsyncThunk(
  `${ADMIN}/${ATTENDANCE_RECORDS}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/attendance_records`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const deleteVisitingReport = createAsyncThunk(
  `${ADMIN}/DELETE_VISITING_REPORT`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/attendance_records/${id}`;

    return handleAPI(url, {}, "DELETE");
  }
);

// IGF Reports
export const loadIgfRecords = createAsyncThunk(`${ADMIN}/${IGF}`, (params) => {
  const url = `/${API_VERSION_ONE}/admin/igf_reports`;
  const method = "GET";

  return handleAPI(url, { ...params }, method);
});

export const deleteIgfReport = createAsyncThunk(
  `${ADMIN}/DELETE_IGF_REPORT`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/igf_reports/${id}`;

    return handleAPI(url, {}, "DELETE");
  }
);

export const loadIgfItemRecords = createAsyncThunk(
  `${ADMIN}/${IGF_ITEMS}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/igf_reports/group_by_product`;
    const method = "GET";

    return handleAPI(url, { ...params }, method);
  }
);

export const loadIgfBranchRecords = createAsyncThunk(
  `${ADMIN}/${IGF_BRANCHES}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/igf_reports/group_by_branch`;
    const method = "GET";

    return handleAPI(url, { ...params }, method);
  }
);

export const updateIgfReport = createAsyncThunk(
  `${ADMIN}/update_igf`,
  ({ id, params }) => {
    const url = `/${API_VERSION_ONE}/admin/igf_reports/${id}`;
    const method = "PUT";

    return handleAPI(url, params, method);
  }
);

// SDR Reports
export const loadSdrRecords = createAsyncThunk(`${ADMIN}/${SDR}`, (params) => {
  const url = `/${API_VERSION_ONE}/admin/sdr_reports`;
  const method = "GET";

  return handleAPI(url, params, method);
});

export const loadSdrItemRecords = createAsyncThunk(
  `${ADMIN}/${SDR_ITEMS}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/sdr_reports/group_by_product`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const loadSdrBranchRecords = createAsyncThunk(
  `${ADMIN}/${SDR_BRANCHES}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/sdr_reports/group_by_branch`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const deleteSdrReport = createAsyncThunk(
  `${ADMIN}/DELETE_SDR_REPORT`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/sdr_reports/${id}`;

    return handleAPI(url, {}, "DELETE");
  }
);

export const updateSdrReport = createAsyncThunk(
  `${ADMIN}/update_sdr`,
  ({ id, params }) => {
    const url = `/${API_VERSION_ONE}/admin/sdr_reports/${id}`;
    const method = "PUT";

    return handleAPI(url, params, method);
  }
);

// Inventory Reports
export const loadInventoryRecords = createAsyncThunk(
  `${ADMIN}/${INVENTORY}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/inventory_reports`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const loadInventoryItemRecords = createAsyncThunk(
  `${ADMIN}/${INVENTORY_ITEMS}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/inventory_reports/group_by_product`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const deleteInventoryRecord = createAsyncThunk(
  `${ADMIN}/DELETE_INVENTORY`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/inventory_reports/${id}`;

    return handleAPI(url, {}, "DELETE");
  }
);

export const loadImageSections = createAsyncThunk(
  `${ADMIN}/IMAGE_SECTION`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/section_images`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const deleteImageSectionReport = createAsyncThunk(
  `${ADMIN}/DELETE_IMAGE_SECTION`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/section_images/${id}`;

    return handleAPI(url, {}, "DELETE");
  }
);

export const loadInventoryBranchRecords = createAsyncThunk(
  `${ADMIN}/${INVENTORY_BRANCHES}`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/inventory_reports/group_by_branch`;
    const method = "GET";

    return handleAPI(url, params, method);
  }
);

export const updateInventoryReport = createAsyncThunk(
  `${ADMIN}/update_inventory`,
  ({ id, params }) => {
    const url = `/${API_VERSION_ONE}/admin/inventory_reports/${id}`;
    const method = "PUT";

    return handleAPI(url, params, method);
  }
);

export const createRequestReport = createAsyncThunk(
  `${ADMIN}/${REQUEST_REPORT}`,
  (data) => {
    const url = `/${API_VERSION_ONE}/admin/report_requests/`;
    const method = "POST";

    return handleAPI(url, {}, method, data);
  }
);

export const updateRequestReport = createAsyncThunk(
  `${ADMIN}/UPDATE_REQUEST_REPORT`,
  ({ id, data }) => {
    const url = `/${API_VERSION_ONE}/admin/report_requests/${id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);

export const deleteReportImg = createAsyncThunk(
  `${ADMIN}/DELETE_IMAGE`,
  ({ id, data }) => {
    const url = `/${API_VERSION_ONE}/admin/attachments/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method, data);
  }
);

export const exportRecords = createAsyncThunk(
  `${ADMIN}/${EXPORT_RECORDS}`,
  ({ extension = "", report_type = "", ...params }) => {
    const exportUrl = exportingURLs[report_type] || "";
    const exportSection = reportSection[report_type] || "reports";

    const urlWithSection = `/${API_VERSION_ONE}/admin/${exportSection}/${exportUrl}${extension}`;
    const urlWithOutSection = `/${API_VERSION_ONE}/admin/${exportUrl}${extension}`;

    const url = [
      "igf",
      "rtv",
      "section_images",
      "sdr",
      "attendance",
      "inventory",
    ].includes(report_type)
      ? urlWithOutSection
      : urlWithSection;

    return downloadAPI(url, `${report_type}${extension}`, {
      ...params,
      report_type,
    });
  }
);

// RTV Reports
export const loadRtvRecords = createAsyncThunk(`${ADMIN}/RTV`, (params) => {
  const url = `/${API_VERSION_ONE}/admin/rtv_reports`;
  const method = "GET";

  return handleAPI(url, { ...params }, method);
});

export const loadRtvItemRecords = createAsyncThunk(
  `${ADMIN}/RTV_ITEMS`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_reports/group_by_product`;
    const method = "GET";

    return handleAPI(url, { ...params }, method);
  }
);

export const loadRtvBranchRecords = createAsyncThunk(
  `${ADMIN}/RTV_BRANCHES`,
  (params) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_reports/group_by_branch`;
    const method = "GET";

    return handleAPI(url, { ...params }, method);
  }
);

export const deleteRtvReport = createAsyncThunk(
  `${ADMIN}/DELETE_RTV_REPORT`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_reports/${id}`;

    return handleAPI(url, {}, "DELETE");
  }
);

export const updateRtvProduct = createAsyncThunk(
  `${ADMIN}/UPDATE_RTV`,
  ({ id, params }) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_report_products/${id}`;
    const method = "PUT";

    return handleAPI(url, params, method);
  }
);

export const deleteRtvProduct = createAsyncThunk(
  `${ADMIN}/DELETE_RTV_PRODUCT`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_report_products/${id}`;
    const method = "DELETE";

    return handleAPI(url, {}, method);
  }
);

export const loadRtvProductDetails = createAsyncThunk(
  `${ADMIN}/LOAD_PRODUCT_DETAILS`,
  (id) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_reports/${id}`;
    const method = "GET";

    return handleAPI(url, {}, method);
  }
);

export const updateRtvReport = createAsyncThunk(
  `${ADMIN}/UPDATE_RTV_REPORT`,
  ({ id, data }) => {
    const url = `/${API_VERSION_ONE}/admin/rtv_reports/${id}`;
    const method = "PUT";

    return handleAPI(url, {}, method, data);
  }
);
