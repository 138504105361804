import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./styles.module.css";
import Text from "../Text";

// variant: error, warning, success, info

const alertStyles = {
  primary: styles.primary_alert,
  info: styles.info_alert,
  success: styles.success_alert,
  warning: styles.warning_alert,
  error: styles.error_alert,
};

const alertPlacement = {
  top: styles.top_center_alert,
  bottom: styles.bottom_center_alert,
};

// const defaultIcons = {
//     primary: <span />,
//     info: <span />,
//     success: <span />,
//     warning: <span />,
//     error: <span />,
// };

const AlertToaster = ({
  variant,
  message,
  customMessage,
  placement,
  noBorder,
  handleClose,
  onHover,
  onLeave,
  open,
  duration,
  left,
  top,
  right,
  bottom,
  ...rest
}) => {
  React.useEffect(() => {
    if (message && open && duration !== -1) {
      setTimeout(() => {
        handleClose(false);
      }, duration * 1000);
    }
  }, [open, duration]);

  return (
    <>
      {open &&
        ReactDOM.createPortal(
          <div
            {...rest}
            className={clsx(
              styles.container,
              alertStyles[variant],
              alertPlacement[placement],
              noBorder && styles.no_border,
              open ? styles.show_alert : styles.hide_alert
            )}
            style={{
              ...(left && { left }),
              ...(top && { top }),
              ...(bottom && { bottom }),
              ...(right && { right }),
            }}
            onMouseOver={onHover}
            onMouseLeave={onLeave}
          >
            {customMessage || <Text typography="paragraph14">{message}</Text>}
          </div>,
          document.body
        )}
    </>
  );
};

AlertToaster.defaultProps = {
  variant: "primary",
  placement: "bottom",
  duration: 7,
};

AlertToaster.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.string,
  customMessage: PropTypes.node,
  placement: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClose: PropTypes.func,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
  open: PropTypes.bool,
  noBorder: PropTypes.bool,
  duration: PropTypes.number,
};

export default AlertToaster;
