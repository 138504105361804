import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  params: {},
};

const filtersSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    setParams: (state, action) => {
      if (action.payload === "null") {
        state.params = {};
      } else {
        state.params = { ...state.params, ...action.payload };
      }
    },
  },
});

export default filtersSlice.reducer;
export const { setParams } = filtersSlice.actions;
