import React from "react";
import { useNavigate } from "react-router-dom";
import SupervisorRoutes from "routers/authorized/supervisor";
import { getAuthUser } from "redux/integration/auth/actions";
import { MuiBox } from "atoms";
// import HeaderNav from 'components/global/headerNav';
// import SideNav from 'components/global/sideNav';
// import { ContentTemplate } from 'templates';

const Supervisor = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const isFirstLogin = getAuthUser()?.is_change_password_required;
    if (isFirstLogin) navigate("/reset-password");
  }, []);

  return (
    <MuiBox>
      {/* <Flex>
          <SideNav />
          <Flex flexCol fullWidth>
            <HeaderNav />
            <ContentTemplate> */}
      {<SupervisorRoutes />}
      {/* </ContentTemplate>
          </Flex>
        </Flex> */}
    </MuiBox>
  );
};

export default Supervisor;
