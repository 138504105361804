import React from "react";
import PropTypes from "prop-types";
import i18n from "translation/i18n";
import { Spacer, Flex, Text, MuiPopover } from "atoms";
import { statusColor } from "utils/uiHelpers";
import { MsmLoader } from "components/global/loaders";
import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "assets/icons/common/bottom-arrow.svg";

import Pagination from "./Pagination";
import TooltipCell from "./TooltipCell";
import Styles from "./Table.module.css";

const Table = (props) => {
  const {
    pagination,
    withNumbering,
    handlePage,
    loading,
    noRowMessage,
    headers,
    rows,
    maxHeight,
    children,
    withChildren,
    className,
  } = props;
  const tableHeaders = [
    withNumbering && {
      label: "#",
      style: { width: 50, maxWidth: 50, minWidth: 50 },
    },
    ...headers,
  ].filter(Boolean);

  const rowDetail = (row, head) => {
    if (head?.renderCell) return row ? head.renderCell(row) : null;
    const label = row?.[head?.field] || "";
    if (typeof label === "object") return;
    if (head?.withTooltip) return <TooltipCell label={label} />;
    if (head?.field === "status" || head?.field === "status_i18n")
      return (
        <Text
          typography="paragraph14"
          weight="bold"
          color={statusColor[row?.status]}
        >
          {row?.status_i18n || i18n.t(row?.status) || ""}
        </Text>
      );

    return (
      <Text typography="paragraph14" weight="bold">
        {label}
      </Text>
    );
  };

  return (
    <React.Fragment>
      <div
        style={{ maxHeight, overflow: "scroll", border: "1px solid #e6e6e6" }}
      >
        <table className={clsx(Styles.table, className)}>
          <thead>
            <tr>
              {tableHeaders.map((header, i) => (
                <th key={`headerCell-${i}`} style={{ ...header.style }}>
                  <Flex style={{ gap: 8 }} itemsCenter>
                    <Text typography="paragraph14" weight="bold">
                      {header.label || header.title}
                    </Text>
                    {header.floatingAction && (
                      <MuiPopover
                        placement="top"
                        trigger={
                          <Flex
                            className={Styles.arrowIcon}
                            justifyCenter
                            itemsCenter
                          >
                            <ArrowIcon />
                          </Flex>
                        }
                      >
                        {header.floatingAction}
                      </MuiPopover>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!rows.length && (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <Flex justifyCenter>
                    <Text typography="paragraph14" weight="bold">
                      {noRowMessage || i18n.t("no_data")}
                    </Text>
                  </Flex>
                </td>
              </tr>
            )}

            {withChildren
              ? children
              : rows.map((row, i) => (
                  <tr key={`dataRow-${i}`}>
                    {withNumbering && (
                      <td key={`dataCell-${i}`}>
                        <Text typography="paragraph14" weight="bold">
                          {i + 1}
                        </Text>
                      </td>
                    )}
                    {children
                      ? children
                      : headers.map((header, i) => (
                          <td
                            key={`dataCell-${i}`}
                            style={{ ...header.tdStyles }}
                          >
                            {rowDetail(row, header)}
                          </td>
                        ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {loading && <MsmLoader fixed />}

      {pagination && (
        <>
          <Spacer height={16} />
          <Pagination
            handlePage={handlePage}
            {...pagination}
            total_displayed={rows.length}
          />
        </>
      )}
    </React.Fragment>
  );
};

Table.defaultProps = {
  maxHeight: 400,
  withNumbering: true,
  headers: [],
  rows: [],
  pagination: {},
};

Table.propTypes = {
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withNumbering: PropTypes.bool,
  loading: PropTypes.bool,
  noRowMessage: PropTypes.string,
  className: PropTypes.string,
  handlePage: PropTypes.func,
  children: PropTypes.node,
  withChildren: PropTypes.bool,
  pagination: PropTypes.shape(),
  headers: PropTypes.arrayOf(Object),
  rows: PropTypes.arrayOf(Object),
};

export default Table;
