import React from "react";
import PropTypes from "prop-types";
import { Flex, Spacer, Text } from "atoms";
import MsmLoader from "./MsmLoader";

const FullScreenLoader = ({ message, ...props }) => {
  return (
    <Flex flexCol justifyCenter itemsCenter style={{ height: "100vh" }}>
      <MsmLoader {...props} />
      <Spacer height={16} />
      {message && <Text typography="paragraph16">{message}</Text>}
    </Flex>
  );
};

FullScreenLoader.propTypes = {
  message: PropTypes.string,
};

export default FullScreenLoader;
