import React from "react";
import { MuiBox } from "atoms";
import PublicRoutes from "routers/public/PublicRoutes";

const PublicLayout = () => {
  return (
    <MuiBox>
      <PublicRoutes />
    </MuiBox>
  );
};

export default PublicLayout;
