import { createSlice } from "@reduxjs/toolkit";
import { Toastr } from "components/global/toasterHandler/Toastr";
import { handleApiRequestError } from "utils/uiHelpers";
import {
  ADMIN,
  deleteIgfReport,
  deleteRtvReport,
  deleteImageSectionReport,
  deleteSdrReport,
  deleteInventoryRecord,
  exportRecords,
  loadAttendanceRecords,
  loadIgfBranchRecords,
  loadRtvBranchRecords,
  loadIgfItemRecords,
  loadRtvItemRecords,
  loadIgfRecords,
  loadRtvRecords,
  loadImageSections,
  loadInventoryBranchRecords,
  loadInventoryItemRecords,
  loadInventoryRecords,
  loadNotificationRecords,
  loadSdrBranchRecords,
  loadSdrItemRecords,
  loadSdrRecords,
  createRequestReport,
  updateIgfReport,
  updateRtvProduct,
  deleteRtvProduct,
  updateSdrReport,
  deleteReportImg,
  updateInventoryReport,
  deleteVisitingReport,
  updateRequestReport,
  loadExtensionsRequests,
  approveRejectExtensionRequest,
  loadRtvProductDetails,
  updateRtvReport,
} from "./actions";

const initialState = {
  fetching: false,
  exporting: false,
  submitting: false,
  headers: [],
  rows: [],
  pagination: {},
  rtv_report: {},
  success: false,
  message: "",
};

const slice = createSlice({
  name: ADMIN,
  initialState,
  reducers: {
    clearInfo: (state) => {
      state.fetching = false;
      state.headers = [];
      state.rows = [];
      state.pagination = {};
      state.success = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    // Image Sections
    builder.addCase(loadImageSections.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadImageSections.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data.data.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadImageSections.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // delete Image Sections
    builder.addCase(deleteImageSectionReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteImageSectionReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteImageSectionReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Notification Records list
    builder.addCase(loadNotificationRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadNotificationRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.rows = action.payload.data?.data?.report_requests;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadNotificationRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Extensions Requests list
    builder.addCase(loadExtensionsRequests.pending, (state) => {
      state.fetching = true;
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadExtensionsRequests.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.rows = action.payload.data?.data?.extension_requests;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadExtensionsRequests.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Attendance Records list
    builder.addCase(loadAttendanceRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadAttendanceRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.headers;
        state.rows = action.payload.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadAttendanceRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // IGF Records list
    builder.addCase(loadIgfRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadIgfRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadIgfRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });
    // Rtv Records list
    builder.addCase(loadRtvRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadRtvRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadRtvRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addCase(loadRtvProductDetails.pending, (state) => {
      state.fetching = true;
      state.rtv_report = [];
    });
    builder.addCase(loadRtvProductDetails.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.rtv_report = action.payload.data?.data?.rtv_report;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadRtvProductDetails.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // IGF Item Records list
    builder.addCase(loadIgfItemRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadIgfItemRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadIgfItemRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Rtv Item Records list
    builder.addCase(loadRtvItemRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadRtvItemRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadRtvItemRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // IGF Branch Records list
    builder.addCase(loadIgfBranchRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadIgfBranchRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadIgfBranchRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Rtv Branch Records list
    builder.addCase(loadRtvBranchRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadRtvBranchRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadRtvBranchRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // SDR Records list
    builder.addCase(loadSdrRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadSdrRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers || [];
        state.rows = action.payload.data?.data?.data?.rows || [];
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadSdrRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // SDR Item Records list
    builder.addCase(loadSdrItemRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadSdrItemRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadSdrItemRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // SDR Branch Records list
    builder.addCase(loadSdrBranchRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadSdrBranchRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadSdrBranchRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Inventory Records list
    builder.addCase(loadInventoryRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadInventoryRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadInventoryRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Inventory Item Records list
    builder.addCase(loadInventoryItemRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadInventoryItemRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadInventoryItemRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Inventory Branch Records list
    builder.addCase(loadInventoryBranchRecords.pending, (state) => {
      state.fetching = true;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });
    builder.addCase(loadInventoryBranchRecords.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        state.headers = action.payload.data?.data?.data?.headers;
        state.rows = action.payload.data?.data?.data?.rows;
        state.pagination = action.payload.data?.data?.pagination;
        state.success = action.payload.data?.success;
        state.message = action.payload.data?.message;
      } else {
        state.message = handleApiRequestError(action.payload);
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(loadInventoryBranchRecords.rejected, (state, action) => {
      state.fetching = false;
      state.message = handleApiRequestError(action.payload);
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Export
    builder.addCase(exportRecords.pending, (state) => {
      state.exporting = true;
    });
    builder.addCase(exportRecords.fulfilled, (state) => {
      state.exporting = false;
    });
    builder.addCase(exportRecords.rejected, (state) => {
      state.exporting = false;
    });

    // Requesting a Report
    builder.addCase(createRequestReport.pending, (state) => {
      state.submitting = true;
    });
    builder.addCase(createRequestReport.fulfilled, (state, action) => {
      state.submitting = false;

      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(createRequestReport.rejected, (state, action) => {
      state.submitting = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Request a Report
    builder.addCase(updateRequestReport.pending, (state) => {
      state.submitting = true;
    });
    builder.addCase(updateRequestReport.fulfilled, (state, action) => {
      state.submitting = false;

      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateRequestReport.rejected, (state, action) => {
      state.submitting = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Approve Reject Extension Request
    builder.addCase(approveRejectExtensionRequest.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(
      approveRejectExtensionRequest.fulfilled,
      (state, action) => {
        state.fetching = false;
        if (action.payload?.data?.success) {
          Toastr.success(action.payload.data?.message);
        } else {
          Toastr.error(handleApiRequestError(action.payload));
        }
      }
    );
    builder.addCase(approveRejectExtensionRequest.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addCase(updateRtvReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateRtvReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateRtvReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    builder.addDefaultCase(undefined, (state) => {
      state.fetching = false;
      state.headers = [];
      state.rows = [];
      state.message = "";
    });

    // Delete igf report
    builder.addCase(deleteIgfReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteIgfReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteIgfReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete rtv report
    builder.addCase(deleteRtvReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteRtvReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteRtvReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete Visiting report
    builder.addCase(deleteVisitingReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteVisitingReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteVisitingReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete report image
    builder.addCase(deleteReportImg.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteReportImg.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteReportImg.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update igf report
    builder.addCase(updateIgfReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateIgfReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateIgfReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update rtv report
    builder.addCase(updateRtvProduct.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateRtvProduct.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateRtvProduct.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update rtv report
    builder.addCase(deleteRtvProduct.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteRtvProduct.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteRtvProduct.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete sdr report
    builder.addCase(deleteSdrReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteSdrReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteSdrReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update sdr report
    builder.addCase(updateSdrReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateSdrReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateSdrReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Update Inventory report
    builder.addCase(updateInventoryReport.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(updateInventoryReport.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(updateInventoryReport.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });

    // Delete Inventory report
    builder.addCase(deleteInventoryRecord.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(deleteInventoryRecord.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload?.data?.success) {
        Toastr.success(action.payload.data?.message);
      } else {
        Toastr.error(handleApiRequestError(action.payload));
      }
    });
    builder.addCase(deleteInventoryRecord.rejected, (state, action) => {
      state.fetching = false;
      Toastr.error(handleApiRequestError(action.payload));
    });
  },
});

export default slice.reducer;
export const { clearInfo } = slice.actions;
