import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import "./styles.css";

const MuiTooltip = ({ children, ...rest }) => (
  <Tooltip
    arrow
    aria-describedby="tooltip"
    style={{ textAlign: "center" }}
    {...rest}
  >
    {children}
  </Tooltip>
);

MuiTooltip.propTypes = {
  children: PropTypes.node,
};

export default MuiTooltip;
