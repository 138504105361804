import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import ToolTipHandler from "components/global/toolTipHandler";

const textEllipsis = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TooltipCell = ({ label, style }) => {
  return (
    <ToolTipHandler show title={label} style={{ ...style, textEllipsis }}>
      <Text typography="paragraph14" weight="bold" style={textEllipsis}>
        {label}
      </Text>
    </ToolTipHandler>
  );
};

TooltipCell.propTypes = {
  label: PropTypes.string,
  style: PropTypes.shape(),
};

export default TooltipCell;
