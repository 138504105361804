import store from "redux/store";
import { ACCESS_TOKEN } from "models";
import { removeAuthUser } from "redux/integration/auth/actions";

/*
  this function for handle global error response from axios
*/

function errorResponseHandler(error) {
  const { response } = error;
  if (response) {
    switch (response.status) {
      case 401:
        store.dispatch(removeAuthUser());
        window.location.href = "/login";
        break;

      case 403:
        if (localStorage.getItem(ACCESS_TOKEN)) {
          window.location.href = "/";
        }
        break;

      default:
        break;
    }
  }

  return Promise.reject(error);
}

export default errorResponseHandler;
