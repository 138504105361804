import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";

const MuiDivider = ({ width, height, color, style, ...rest }) => (
  <Divider
    {...rest}
    style={{
      borderStyle: "hidden",
      ...style,
      width,
      height,
      backgroundColor: color,
    }}
  />
);

MuiDivider.defaultProps = {
  color: "var(--brand-border-primary)",
  width: "100%",
  height: 1,
};

MuiDivider.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  color: PropTypes.string,
};

export default MuiDivider;
