import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import { enUS, arSA } from "date-fns/locale";
import clsx from "clsx";
import { MuiBox, Text } from "atoms"; // MuiTextInput
import { isAr } from "utils/uiHelpers";
import { ReactComponent as CalendarIcon } from "assets/icons/common/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.module.css";

registerLocale("ar", arSA);
registerLocale("en", enUS);

// const CustomInput = (props) => {
// 	return (
// 		<MuiBox  className={styles.custom_input} {...props} />
// 	);
// };

const MuiDatepicker = (props) => {
  const {
    style,
    placeholder,
    label,
    labelClassname,
    status,
    onChange,
    value,
    hideIcon,
    removeIconAr = false,
    dateFormat = "dd-MMM-yyyy",
  } = props;
  const textStatuses = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ];
  const inputStatus = React.useMemo(
    () => (textStatuses.includes(status) ? status : ""),
    [status]
  );
  const [inputValue, setInputValue] = React.useState(value);
  const statusColors = {
    error: styles.error_textfield,
  };

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  React.useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  return (
    <MuiBox
      className={clsx(
        styles.datepicker_container,
        !removeIconAr && isAr() && styles.datepicker_container_ar
      )}
    >
      <Text
        typography="caption13"
        className={clsx(styles.datepicker_label, labelClassname)}
      >
        {label}
      </Text>
      <DatePicker
        {...props}
        style={{ ...style }}
        className={clsx(styles.datepicker, statusColors[inputStatus])}
        wrapperClassName={styles.wrapper_className}
        placeholderText={placeholder}
        title={label}
        onChange={(date) => {
          setInputValue(date);
        }}
        value={inputValue}
        popperModifiers={{
          offset: { enabled: true, offset: "0px, 10px" },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
        dateFormat={dateFormat}
        locale={localStorage.getItem("Locale")}
        // customInput={(params) => <MuiTextInput {...params} />}
        // renderInput={(params) => <MuiTextInput {...params} />}
      />
      {!hideIcon && <CalendarIcon height={20} width={20} />}
    </MuiBox>
  );
};

MuiDatepicker.propTypes = {
  style: PropTypes.shape(),
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  labelClassname: PropTypes.string,
  status: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  removeIconAr: PropTypes.bool,
  hideIcon: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
};

export default MuiDatepicker;
